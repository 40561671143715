import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import DropDownMenu from "../../components/DropDownMenu";
import { useEffect, useState } from "react";
import {
  dateFormater,
  fetchingData,
  fetchingDataForChart,
  formatTime,
} from "../../Utils/helpers";
import PdfTemplate from "../../components/PdfTemplate";
import LineChart from "../../components/LineChart";
import CustomTabs from "../../components/Tabs";
// import  '@react-pdf/renderer'
// ===================================================
import { FaCarSide } from "react-icons/fa";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FaMoneyBill1 } from "react-icons/fa6";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { mockDataContacts } from "../../data/mockData";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import BarChart from "../../components/BarChart";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import RevenueChart from "./RevenuChart";
// icons-------------------------
import { IoCloudDownloadOutline } from "react-icons/io5";
// import { IoCloudDownloadOutline } from "react-icons/io5";
import { TbTicketOff } from "react-icons/tb";
import { BsFillTicketDetailedFill } from "react-icons/bs";
import { IoCarSharp } from "react-icons/io5";
import StatBoxExtend from "../../components/StatBoxExtend";
import CarFLowChart from "./CarFLowChart";
import StatisticesTable from "./StatisticesTable";
import TicketByTypeRepo from "./TicketByTypeRepo";
import OccupationRate from "./OccupationRate";
import {
  Rapport_Tickets_par_Types_periodic,
  StatisticesReport_Periodic,
  DailyReport_PeriodicPdf,
} from "../../components/Pdf Templates/statisticesRep";

const Month = ({ socket }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [startDate, setStartDate] = useState(dayjs().startOf("month"));
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const [periodicOccupanyRateData, setPeriodicOccupanyRateData] = useState([]);
  const [periodicInOutData, setPeriodicInOutData] = useState([]);
  const [revenueOfSpecificPeriod, setRevenueOfSpecificPeriod] = useState([]);
  const [currentPeriodReport, setCurrentPeriodReport] = useState({
    id: 204943,
    in_count: 0,
    out_count: 0,
    out_amount: 0,
    pt_count: 0,
    pt_days: 0,
    pt_amount: 0,
    mt_count: 0,
    mt_amount: 0,
  });
  const [ticketByTypes, setTicketsByTypes] = useState({
    free_cars: "0|0",
    count_between_2_and_3: "0|0",
    count_between_4_and_5: "0|0",
    count_between_6_and_60: "0|0",
    count_between_61_and_120: "0|0",
    count_between_121_and_180: "0|0",
    more_than_180: "0|0",
  });

  const [restDataOfTheTable, setRestDataOfTheTable] = useState({
    avrage_occupation_rate: 0,
    free_tickets: 0,
    avrage_time: 0,
    morning_entry_count: 0,
    morning_exit_count: 0,
    subscribers_entry_count: 0,
    subscribers_exit_count: 0,
    prev_period_amount: 0,
  });
  const [basicMonthlyStatistices, setBasicMonthlyStatistices] = useState([]);
  const [
    revenuForTheDaysOFTheCurrentMonth,
    setRevenuForTheDaysOFTheCurrentMonth,
  ] = useState([
    {
      id: "Revenu Of The Month",
      color: colors.blueAccent[200],
      data: [],
    },
  ]);
  const [parkings, setParkings] = useState([
    {
      id: 1,
      title: "Bab Jdid P1-P2",
      size: 802,
    },
    {
      id: 2,
      title: "Bab Jdid P3",
      size: 105,
    },
    {
      id: 3,
      title: "Ain Azlitan principale",
      size: 165,
    },
    {
      id: 4,
      title: "Ain Azlitan Annex",
      size: 30,
    },
    {
      id: 5,
      title: "Bab Boujloud",
      size: 153,
    },
    {
      id: 6,
      title: "Bab Guissa",
      size: 172,
    },
    {
      id: 6,
      title: "Oued Zhoune",
      size: 127,
    },
    {
      id: 7,
      title: "Bin Lemdoune",
      size: 203,
    },
    {
      id: 8,
      title: "Bab Hamra",
      size: 92,
    },
  ]);

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "in_count", headerName: "Entry Count" },
    {
      field: "out_count",
      headerName: "Exit Count",
      //   flex: 1,
      //   cellClassName: "name-column--cell",
    },
    {
      field: "out_amount",
      headerName: "Reguler Amount",
      flex: 1.1,
      //   type: "number",
      //   headerAlign: "left",
      //   align: "left",
    },
    {
      field: "pt_count",
      headerName: "PT Count",
      flex: 1,
    },
    {
      field: "pt_days",
      headerName: "PT Days",
      flex: 1,
    },
    {
      field: "pt_amount",
      headerName: "PT Amount",
      flex: 1,
    },
    {
      field: "mt_count",
      headerName: "MT count",
      flex: 1,
    },
    {
      field: "mt_amount",
      headerName: "MT Amount",
      flex: 1,
    },
    {
      field: "date_time",
      headerName: "Date",
      flex: 1,
      valueGetter: (value) => {
        return dateFormater(value.value);
      },
    },
    {
      headerName: "Print",
      flex: 1.5,
      renderCell: (row) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={colors.greenAccent[600]}
            borderRadius="4px"
          >
            <PictureAsPdfIcon color="white" />
            <Typography color="white" sx={{ ml: "5px" }}>
              Print
            </Typography>

            {/* <PDFDownloadLink document={<PdfTemplate />} fileName="example.pdf">
                {({ blob, url, loading, error }) =>
                    loading ? 'Loading document...' : 'Download PDF'
                }
            </PDFDownloadLink> */}
          </Box>
        );
      },
    },
    //   renderCell:({row: {date_time}})=>{
    //     ret
    //   }
    //   renderCell: ({ row: { access } }) => {
    //     return (
    //       <Box
    //         width="60%"
    //         m="0 auto"
    //         p="5px"
    //         display="flex"
    //         justifyContent="center"
    //         backgroundColor={
    //           access === "admin"
    //             ? colors.greenAccent[600]
    //             : access === "manager"
    //             ? colors.greenAccent[700]
    //             : colors.greenAccent[700]
    //         }
    //         borderRadius="4px"
    //       >
    //         {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
    //         {access === "manager" && <SecurityOutlinedIcon />}
    //         {access === "user" && <LockOpenOutlinedIcon />}
    //         <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
    //           {access}
    //         </Typography>
    //       </Box>
    //     );
    //   },
    // },
  ];

  const handlePakringChanged = (Parking, setAnchorEl) => {
    setCurrentParking(Parking);
    setAnchorEl(null);
  };
  const [currentParking, setCurrentParking] = useState({
    id: 0,
    size: 1800,
    title: "Tous les parkings",
  });

  useEffect(() => {
    if (startDate >= endDate) {
      setStartDate(dayjs(endDate).subtract(1, "day"));
    }

    // if (startDate < dayjs(endDate).subtract(30, "day")) {
    //   setEndDate(dayjs(startDate).add(30, "day"));
    // }

    if (startDate < endDate) {
      setCurrentPeriodReport({
        in_count: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
        out_count: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
        out_amount: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
        pt_count: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
        pt_days: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
        pt_amount: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
        mt_count: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
        mt_amount: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
      });
      setRestDataOfTheTable({
        avrage_occupation_rate: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
        free_tickets: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
        avrage_time: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
        morning_entry_count: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
        morning_exit_count: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
        subscribers_entry_count: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
        subscribers_exit_count: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
        prev_period_amount: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
      });

      if (socket) {
        socket.emit("MonthlyReport/start", {
          parking_id: currentParking.id,
          start_date: startDate.format("YYYY-MM-D"),
          end_date: endDate.format("YYYY-MM-D"),
        });

        socket.on("Monthly/ThisPeriodReport", ({ data }) => {
          data = data.data[0];
          setCurrentPeriodReport({
            id: parseInt(data?.id) || 0,
            in_count: parseInt(data?.in_count) || 0,
            out_count: parseInt(data?.out_count) || 0,
            out_amount: `${parseInt(data?.out_amount) || 0}DH`,
            pt_count: parseInt(data?.pt_count) || 0,
            pt_days: parseInt(data?.pt_days) || 0,
            pt_amount: `${parseInt(data?.pt_amount) || 0}DH`,
            mt_count: parseInt(data?.mt_count) || 0,
            mt_amount: `${parseInt(data?.mt_amount) || 0}DH`,
          });
        });

        socket.on("Monthly/periodicTableReport", ({ data }) => {
          data = data.data[0];
          // console.log("====================================");
          // console.log("data", data);
          // console.log("====================================");
          setRestDataOfTheTable((prev) => ({
            ...prev,
            free_tickets: parseInt(data?.free_tickets || 0),
            avrage_time: formatTime(data?.total_time || 0) || 0,
            morning_entry_count: parseInt(data?.morning_entry || 0),
            morning_exit_count: parseInt(data?.morning_exit || 0),
            subscribers_entry_count: data?.subscribers_entry_count || 0,
            subscribers_exit_count: data?.subscribers_exit_count || 0,
            prev_period_amount: `${parseInt(data?.prev_day_amount || 0)}DH`,
          }));
        });
        socket.on("Monthly/periodicOccupanyRate", ({ data }) => {
          setRestDataOfTheTable((prev) => ({
            ...prev,
            avrage_occupation_rate: 0,
          }));
          setPeriodicOccupanyRateData(data);

          data.data.map((item) => {
            setRestDataOfTheTable((prev) => {
              return {
                ...prev,
                avrage_occupation_rate:
                  prev.avrage_occupation_rate + parseInt(item.y) / 24,
              };
            });
          });
        });

        socket.on("Monthly/inOutCarFlowForPeriod", ({ data }) => {
          console.log("====================================");
          console.log("inOutData", data);
          console.log("====================================");
          setPeriodicInOutData(data);
        });

        socket.on("Monthly/revenueOfSpecificPeriod", ({ data }) => {
          // console.log("====================================");
          // console.log("inOutData", data);
          // console.log("====================================");
          setRevenueOfSpecificPeriod(data.data);
        });

        socket.on("Monthly/ticketByTypeReport", ({ data }) => {
          console.log("====================================");
          console.log("ticketByTypeReport", data);
          console.log("====================================");
          setTicketsByTypes((prev) => ({
            free_cars: data.data[0]?.free_cars || "0|0",
            count_between_2_and_3: data.data[0]?.count_between_2_and_3 || "0|0",
            count_between_4_and_5: data.data[0]?.count_between_4_and_5 || "0|0",
            count_between_6_and_60:
              data.data[0]?.count_between_6_and_60 || "0|0",
            count_between_61_and_120:
              data.data[0]?.count_between_61_and_120 || "0|0",
            count_between_121_and_180:
              data.data[0]?.count_between_121_and_180 || "0|0",
            more_than_180: data.data[0]?.more_than_180 || "0|0",
          }));
        });
      }
    }
    fetchingData("report/basicMonthlyStatistices", setBasicMonthlyStatistices);
    fetchingDataForChart(
      "report/revenuForTheDaysOFTheCurrentMonth",
      setRevenuForTheDaysOFTheCurrentMonth
    );
    // fetchingData("report/daily?date=2024-08-01", setMonth);
    // getAll Parkings =====================
    // fetchingData(`global/getAllParkings`, setParkings);
  }, [startDate, endDate, currentParking]);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Rapport Mensuel"
          subtitle="Le rapport mensuel d'aujourd'hui met en avant les activités clés et les progrès réalisés."
        />

        <Box
          display={"flex"}
          flex={2}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs} key={1}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                value={startDate}
                onChange={(value) => setStartDate(value)}
                label="Start Date"
              />
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} key={2}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                value={endDate}
                onChange={(value) => setEndDate(value)}
                label="End Date"
              />
            </DemoContainer>
          </LocalizationProvider>
          <DropDownMenu
            parkings={parkings}
            selectedItem={currentParking}
            handleChanged={handlePakringChanged}
          />
        </Box>

        {/* <PDFDownloadLink document={<PdfTemplate />} fileName="example.pdf">
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Download PDF"
          }
        </PDFDownloadLink> */}
      </Box>

      {/* ============== */}
      <Box
        display={"flex"}
        // flexDirection={"row-reverse"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        mb={2}
        gap={1}
      >
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          style={{
            backgroundColor: colors.blueAccent[700],
          }}
          endIcon={<IoCloudDownloadOutline />}
          onClick={() =>
            Rapport_Tickets_par_Types_periodic(
              ticketByTypes,
              currentParking,
              currentPeriodReport,
              startDate,
              endDate
            )
          }
        >
          Reparition Des Paiments Par Montants Paye
        </Button>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          style={{
            backgroundColor: colors.blueAccent[700],
          }}
          endIcon={<IoCloudDownloadOutline />}
          onClick={() =>
            StatisticesReport_Periodic({
              startDate: startDate.format("YYYY-MM-D"),
              endDate: endDate.format("YYYY-MM-D"),
              currentParkingName: currentParking.title,
              visitor_entry_count:
                parseInt(currentPeriodReport.in_count) -
                restDataOfTheTable.subscribers_entry_count,
              subscribers_entry_count:
                restDataOfTheTable.subscribers_entry_count,
              visitor_exit_count:
                parseInt(currentPeriodReport?.out_count || 0) +
                parseInt(currentPeriodReport?.mt_count || 0) +
                parseInt(currentPeriodReport?.pt_count || 0) -
                restDataOfTheTable.subscribers_exit_count,
              subscribers_exit_count: restDataOfTheTable.subscribers_exit_count,
              free_tickets: restDataOfTheTable?.free_tickets || 0,
              in_count: currentPeriodReport?.in_count || 0,
              out_count:
                parseInt(currentPeriodReport?.out_count) +
                  parseInt(currentPeriodReport?.mt_count) +
                  parseInt(currentPeriodReport?.pt_count) || 0,
              parking_size: currentParking?.size || 3000,

              avrage_occupation_rate:
                (restDataOfTheTable?.avrage_occupation_rate / 24).toFixed(2) ||
                0,
              avrage_time: restDataOfTheTable?.avrage_time || "0h : 0m : 0s",
              total_amount:
                parseInt(currentPeriodReport?.out_amount) +
                  parseInt(currentPeriodReport?.mt_amount) +
                  parseInt(currentPeriodReport?.pt_amount) || 0,
            })
          }
        >
          Statistiques Du Parking
        </Button>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          style={{
            backgroundColor: colors.blueAccent[700],
          }}
          endIcon={<IoCloudDownloadOutline />}
          onClick={() =>
            DailyReport_PeriodicPdf(
              currentParking,
              restDataOfTheTable,
              currentPeriodReport,
              startDate,
              endDate
            )
          }
        >
          Flix & chiffre d'affaire
        </Button>
      </Box>

      {/* -==================== */}

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="150px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            title={currentPeriodReport.in_count}
            subtitle="Les Entrées de ce mois"
            // progress={2}
            // increase={100}
            // progress={calculate_percentage(
            //   statistics.in_count,
            //   statistics.last_in_count
            // )}
            // increase={calculate_percentage_compared_to_yesterday(
            //   statistics.in_count,
            //   statistics.last_in_count
            // )}
            icon={
              <FaCarSide
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            title={currentPeriodReport.out_count}
            subtitle="Les Sorties de ce mois"
            // progress={2}
            // increase={100}
            // progress={calculate_percentage(
            //   statistics.out_count,
            //   statistics.last_out_count
            // )}
            // increase={calculate_percentage_compared_to_yesterday(
            //   statistics.out_count,
            //   statistics.last_out_count
            // )}
            icon={
              <IoCarSharp
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>

        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            // title={`${statistics.today_amount}DH`}
            title={currentPeriodReport.pt_amount}
            subtitle="Montant de Ticket Perdu"
            // progress={2}
            // increase={100}
            icon={
              // <FaMoneyBillTrendUp
              //   style={{ color: colors.greenAccent[600], fontSize: "26px" }}
              // />
              <TbTicketOff
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            // title={`${statistics.today_amount}DH`}
            title={currentPeriodReport.mt_amount}
            subtitle="Montant de Ticket Illisible"
            // progress={2}
            // increase={100}
            icon={
              // <FaMoneyBillTrendUp
              //   style={{ color: colors.greenAccent[600], fontSize: "26px" }}
              // />
              <BsFillTicketDetailedFill
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>

        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            // title={`${statistics.today_amount}DH`}
            title={
              isNaN(parseInt(currentPeriodReport.out_amount))
                ? currentPeriodReport.out_amount
                : `${
                    parseInt(currentPeriodReport.out_amount) +
                    parseInt(currentPeriodReport.pt_amount) +
                    parseInt(currentPeriodReport.mt_amount)
                  }DH`
            }
            subtitle="Montant total de ce mois"
            // progress={2}
            // increase={100}
            // progress={calculate_percentage(
            //   statistics.today_amount,
            //   statistics.yester_day_amount
            // )}
            // increase={calculate_percentage_compared_to_yesterday(
            //   statistics.today_amount,
            //   statistics.yester_day_amount
            // )}
            icon={
              <FaMoneyBillTrendUp
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            // title={`${statistics.today_amount}DH`}
            title={restDataOfTheTable.prev_period_amount}
            subtitle="Le montant total du mois dernier"
            // progress={2}
            // increase={100}
            // progress={calculate_percentage(
            //   statistics.today_amount,
            //   statistics.yester_day_amount
            // )}
            // increase={calculate_percentage_compared_to_yesterday(
            //   statistics.today_amount,
            //   statistics.yester_day_amount
            // )}
            icon={
              <FaMoneyBill1
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>
      </Box>

      {/* =========================== */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="500px"
        mt="20px"
        gap={"20px"}
      >
        <Box
          gridColumn="span 7"
          gridRow="span 2"
          display={"flex"}
          flexDirection={"column"}
          gap={2}
        >
          <RevenueChart RevenueData={revenueOfSpecificPeriod} />
          <CarFLowChart inOutData={periodicInOutData} />
          <OccupationRate periodicOccupanyRateData={periodicOccupanyRateData} />
        </Box>

        <Box
          gridColumn="span 5"
          gridRow="span 2"
          display={"flex"}
          flexDirection={"column"}
          gap={2}
        >
          <StatisticesTable
            currentPeriodReport={currentPeriodReport}
            currentParking={currentParking}
            restDataOfTheTable={restDataOfTheTable}
            daysDiff={endDate.diff(startDate, "days")}
          />
          <TicketByTypeRepo
            currentPeriodReport={currentPeriodReport}
            currentParking={currentParking}
            ticketByTypes={ticketByTypes}
          />
        </Box>

        {/* <Box height="100px">
        </Box> */}
        {/* <Box height="350px" flex={1}>
          <BarChart isDashboard={true} />
        </Box> */}
        {/* <Box display="flex">
          <Box height="350px" flex={2}>
            <BarChart />
          </Box>
        </Box> */}
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={[]}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Month;
