import { Box, Button, Typography } from "@mui/material";
import { mockDataTeam } from "../../data/mockData";
import { DataGrid } from "@mui/x-data-grid";
import DropDownMenu from "../../components/DropDownMenu";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useState } from "react";
import Header from "../../components/Header";

// icons
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import FormDialog from "./FormDialog";

const SendMail = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [dailyReport, setDailyReport] = useState([]);
  const [parkings, setParkings] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (row) => {
    console.log(row);
  };

  const handlePakringChanged = (Parking, setAnchorEl) => {
    setCurrentParking(Parking);
    setAnchorEl(null);
  };
  const [currentParking, setCurrentParking] = useState({
    id: 1,
    title: "Bab Jdid P1-P2",
  });

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Username",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
    },
    {
      field: "creaetd_at",
      headerName: "Created At",
      flex: 1,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      flex: 1,
    },
    {
      field: "acctions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row: { id, access } }) => {
        return (
          <Box display="flex" gap={2}>
            <Box
              // width="60%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor={colors.greenAccent[600]}
              borderRadius="4px"
              onClick={() => handleClick(access)}
            >
              {/* {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {access === "supervisor" && <SecurityOutlinedIcon />}
            {access === "caissier" && <LockOpenOutlinedIcon />} */}
              <Typography color={colors.blueAccent[100]} sx={{ ml: "5px" }}>
                Update
              </Typography>
            </Box>
            <Box
              width="60%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor={colors.redAccent[600]}
              borderRadius="4px"
              onClick={() => handleClick(id)}
            >
              {/* {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {access === "supervisor" && <SecurityOutlinedIcon />}
            {access === "caissier" && <LockOpenOutlinedIcon />} */}
              <Typography color={colors.redAccent[100]} sx={{ ml: "5px" }}>
                Delete
              </Typography>
            </Box>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Send Mail"
          subtitle="Ce tableau est la liste des Caissières du parking"
        />
        <Button
          onClick={handleClickOpen}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            minWidth: 180,
          }}
        >
          {/* <ExpandMoreIcon// sx={{ mr: "10px" }} /> */}
          {/* {selectedItem.title} */}
          Add Mail
        </Button>
        {/* <Box>
          <DropDownMenu
            parkings={parkings}
            selectedItem={currentParking}
            handleChanged={handlePakringChanged}
          />
        </Box> */}

        {/* <PDFDownloadLink document={<PdfTemplate />} fileName="example.pdf">
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download PDF"
        }
      </PDFDownloadLink> */}
      </Box>
      <Box
        height="74vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection rows={mockDataTeam} columns={columns} />
      </Box>

      <FormDialog
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      />
    </Box>
  );
};

export default SendMail;
