import React from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Box, Typography, useTheme } from "@mui/material";
import { CGP_IMAGE } from "../Constant";

// icons ----------------------------------------------------------
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { tokens } from "../theme";
import { ImOffice } from "react-icons/im";

const GeneratePdf = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const generatePDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    // Title
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text("Statistiques d'Abonnements Bab Jdid P1-P2", pageWidth / 2, 20, {
      align: "center",
    });

    const base64Image = CGP_IMAGE;
    doc.addImage(base64Image, "PNG", pageWidth - 35, 12, 30, 13);
    // Subtitle
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(
      "Ce rapport présente le chiffres d'affaire des visiteurs ainsi que",
      pageWidth / 2 - 30,
      40
    );
    doc.text(
      "le détail des entrées sorties dans un parking pendant une période donnée.",
      pageWidth / 2 - 30,
      44
    );
    const bgColor = { r: 151, g: 200, b: 237 };
    doc.setFillColor(bgColor.r, bgColor.g, bgColor.b);
    doc.rect(0, 50, pageWidth, 10, "F");
    // Adding a line
    // doc.setLineWidth(0.5);
    // doc.line(10, 40, 200, 40);

    // Add some body text
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text("CA visiteurs du parkings Bab Jdid P1-P2", 10, 57);

    // doc.(16);
    doc.setFont("helvetica", "normal");
    doc.text("Chiffre d'affaires du : 2024-09-07", 10, 70);
    doc.text("Parking Bab Jdid P1-P2", 10, 78);

    // Adding a table
    const columns = [
      "Parking",
      "",
      "",
      "N.E Jour",
      "N.E Nuit",
      "N.S Jour",
      "N.S Nuit",
      "N.T Illisible",
      "Tk.Perdus",
    ];
    const rows = [
      [{ content: "Bab Jdid P1-P2", colSpan: 3 }, 401, 200, 302, 112, 42, 3],
      [
        { content: "Nomber Total", colSpan: 3 },
        { content: 601, colSpan: 2 },
        { content: 414, colSpan: 2 },
        "-",
        "4 Days",
      ],
      [
        { content: "Total", colSpan: 3 },
        { content: "-", colSpan: 2 },
        { content: "4525DH", colSpan: 2 },
        "40DH",
        "160DH",
      ],
      [
        { content: "Chiffre d'affaires global", colSpan: 5 },
        { content: "4521DH", colSpan: 5, fillColor: [0, 102, 204] },
      ],
      // [{ content: "Nombre Total", colSpan: 2}, {content: 601, colSpan: 2}, 302, 112, 42, 3]
      // [1, "John Doe", "2024-09-07", "$200", "754"],
      // [1, "John Doe", "2024-09-07", "$200", "754"],
      // [1, "John Doe", "2024-09-07", "$200", "754"],
      // [1, "John Doe", "2024-09-07", "$200", "754"],
      // [1, "John Doe", "2024-09-07", "$200", "754"],
    ];
    console.log('====================================');
    console.log(data.row);
    console.log('====================================');
    // Use autoTable to generate the table
    doc.autoTable({
      startY: 85, // Y position where the table starts
      head: [columns],
      body: rows,
      theme: "grid",
      headStyles: {
        fillColor: [0, 102, 204], // Blue header background
        textColor: [255, 255, 255], // White header text
        fontSize: 10, // Font size of the header
      }, // Optional: 'striped', 'plain', or 'grid'
      styles: { halign: "center" }, // Optional: customize colors
    });

    // Save the PDF
    doc.setFont("helvetica");
    doc.setFontSize(12);
    doc.text("Compagnie General Des Parking", pageWidth / 2, pageHeight - 10, {
      align: "center",
    });
    doc.save("custom-generated.pdf");
  };

  return (
    // <div>
    //   <button onClick={generatePDF}>Generate PDF</button>
    // </div>
    <div onClick={generatePDF}>
      <Box
        m="0 auto"
        p="5px"
        display="flex"
        justifyContent="center"
        backgroundColor={colors.greenAccent[600]}
        borderRadius="4px"
        sx={{
          // backgroundColor: 'primary.main',
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <PictureAsPdfIcon color="white" />
        <Typography color="white" sx={{ ml: "5px" }}>
          Print
        </Typography>

        {/* <PDFDownloadLink document={<PdfTemplate />} fileName="example.pdf">
          {({ blob, url, loading, error }) =>
              loading ? 'Loading document...' : 'Download PDF'
          }
      </PDFDownloadLink> */}
      </Box>
    </div>
  );
};

export default GeneratePdf;
