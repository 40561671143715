import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./AuthProvider";

// import { AuthProvider } from "react-oidc-context";

// const cognitoAuthConfig = {
//   authority: "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_bEAR0nGpE",
//   client_id: "7gi2oumbs0auejmc1d49p4dvp2",
//   redirect_uri: "https://cgpdashboard.online/",
//   response_type: "code",
//   scope: "phone openid email",
// };

const root = ReactDOM.createRoot(document.getElementById("root"));
// wrap the application with AuthProvider
// root.render(
//   <React.StrictMode>
//     <AuthProvider {...cognitoAuthConfig}>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </AuthProvider>
//   </React.StrictMode>
// );
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// root.render(<React.StrictMode></React.StrictMode>);
