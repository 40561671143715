import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function SubscribersTypesTable({subscribersCategoriesCountes}) {
  return (
    <TableContainer backgroundColor={"#141b2d"}>
      <Table
        sx={{
          // minWidth: 650,
          backgroundColor: "#141b2d",
          border: "1px solid",
          // margin: 3,
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: 14, fontWeight: "bold" }}>
              Type Abonnement
            </TableCell>
            <TableCell sx={{ fontSize: 14, fontWeight: "bold" }} align="right">
              24h
            </TableCell>
            <TableCell sx={{ fontSize: 14, fontWeight: "bold" }} align="right">
              Jour
            </TableCell>
            <TableCell sx={{ fontSize: 14, fontWeight: "bold" }} align="right">
              Nuit
            </TableCell>
            <TableCell sx={{ fontSize: 14, fontWeight: "bold" }} align="right">
              Authres
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            key={1}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              component="th"
              scope="row"
              sx={{ fontSize: 14, fontWeight: "bold" }}
            >
              Commercial
            </TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.co_count_24}</TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.co_count_8}</TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.co_count_10}</TableCell>
            <TableCell align="right">0</TableCell>
          </TableRow>
          <TableRow
            key={2}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              sx={{ fontSize: 14, fontWeight: "bold" }}
              component="th"
              scope="row"
            >
              Artisan
            </TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.ar_count_24}</TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.ar_count_8}</TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.ar_count_10}</TableCell>
            <TableCell align="right">0</TableCell>
          </TableRow>
          <TableRow
            key={2}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              sx={{ fontSize: 14, fontWeight: "bold" }}
              component="th"
              scope="row"
            >
              Resident
            </TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.re_count_24}</TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.re_count_8}</TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.re_count_10}</TableCell>
            <TableCell align="right">0</TableCell>
          </TableRow>
          <TableRow
            key={2}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              sx={{ fontSize: 14, fontWeight: "bold" }}
              component="th"
              scope="row"
            >
              Non-Resident
            </TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.no_re_count_24}</TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.no_re_count_8}</TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.no_re_count_10}</TableCell>
            <TableCell align="right">0</TableCell>
          </TableRow>
          <TableRow
            key={2}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              sx={{ fontSize: 14, fontWeight: "bold" }}
              component="th"
              scope="row"
            >
              Professionel
            </TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.pro_count_24}</TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.pro_count_8}</TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.pro_count_10}</TableCell>
            <TableCell align="right">0</TableCell>
          </TableRow>
          <TableRow
            key={2}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              sx={{ fontSize: 14, fontWeight: "bold" }}
              component="th"
              scope="row"
            >
              Education
            </TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.ed_count_24}</TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.ed_count_8}</TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.ed_count_10}</TableCell>
            <TableCell align="right">0</TableCell>
          </TableRow>
          <TableRow
            key={2}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              sx={{ fontSize: 14, fontWeight: "bold" }}
              component="th"
              scope="row"
            >
              Santé
            </TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.st_count_24}</TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.st_count_8}</TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.st_count_10}</TableCell>
            <TableCell align="right">0</TableCell>
          </TableRow>
          <TableRow
            key={2}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              sx={{ fontSize: 14, fontWeight: "bold" }}
              component="th"
              scope="row"
            >
              Tourisme
            </TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.tr_count_24}</TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.tr_count_8}</TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.tr_count_10}</TableCell>
            <TableCell align="right">0</TableCell>
          </TableRow>
          <TableRow
            key={2}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              sx={{ fontSize: 14, fontWeight: "bold" }}
              component="th"
              scope="row"
            >
              Divers
            </TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.dv_count_24}</TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.dv_count_8}</TableCell>
            <TableCell align="right">{subscribersCategoriesCountes.dv_count_10}</TableCell>
            <TableCell align="right">0</TableCell>
          </TableRow>

          <TableRow
            key={2}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              sx={{ fontSize: 14, fontWeight: "bold" }}
              component="th"
              scope="row"
            >
              Montent
            </TableCell>
            <TableCell align="right">0</TableCell>
            <TableCell align="right">0</TableCell>
            <TableCell align="right">0</TableCell>
            <TableCell align="right">0</TableCell>
          </TableRow>
          <TableRow
            key={2}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              sx={{ fontSize: 14, fontWeight: "bold" }}
              component="th"
              scope="row"
            >
              Montant Total
            </TableCell>
            <TableCell
              align="right"
              colSpan={4}
              fontSize="26px"
              fontWeight="bold"
            >
              0
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
