import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function SubTable({ subscribersEntryExits }) {
  return (
    <Box gridColumn="span 6" gridRow="span 2">
      <TableContainer backgroundColor={"#141b2d"}>
        <Table
          sx={{
            // minWidth: 650,
            backgroundColor: "#141b2d",
            border: "1px solid",
            // margin: 3,
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: 14, fontWeight: "bold" }}>
                Type Abonnement
              </TableCell>
              <TableCell
                sx={{ fontSize: 14, fontWeight: "bold" }}
                align="right"
              >
                24h
              </TableCell>
              <TableCell
                sx={{ fontSize: 14, fontWeight: "bold" }}
                align="right"
              >
                Jour
              </TableCell>
              <TableCell
                sx={{ fontSize: 14, fontWeight: "bold" }}
                align="right"
              >
                Nuit
              </TableCell>
              <TableCell
                sx={{ fontSize: 14, fontWeight: "bold" }}
                align="right"
              >
                Authres
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={1}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: 14, fontWeight: "bold" }}
              >
                Entress
              </TableCell>
              <TableCell align="right">
                {subscribersEntryExits["24h"].entry}
              </TableCell>
              <TableCell align="right">
                {subscribersEntryExits["8h"].entry}
              </TableCell>
              <TableCell align="right">
                {subscribersEntryExits["10h"].entry}
              </TableCell>
              <TableCell align="right">0</TableCell>
            </TableRow>
            <TableRow
              key={2}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                sx={{ fontSize: 14, fontWeight: "bold" }}
                component="th"
                scope="row"
              >
                Sorties
              </TableCell>
              <TableCell align="right">
                {subscribersEntryExits["24h"].exit}
              </TableCell>
              <TableCell align="right">
                {subscribersEntryExits["8h"].exit}
              </TableCell>
              <TableCell align="right">
                {subscribersEntryExits["10h"].exit}
              </TableCell>
              <TableCell align="right">0</TableCell>
            </TableRow>
            <TableRow
              key={3}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: 14, fontWeight: "bold" }}
              >
                N.Total / Entrèes{" "}
              </TableCell>
              <TableCell align="center" colSpan={4}>
                {subscribersEntryExits.entryCount}
              </TableCell>
            </TableRow>
            <TableRow
              key={3}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: 14, fontWeight: "bold" }}
              >
                N.Total / Sorties{" "}
              </TableCell>
              <TableCell align="center" colSpan={4}>
                {subscribersEntryExits.exitCount}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
