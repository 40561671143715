import axios from "axios";
import { BASE_URL } from "../Constant";
import { tokens } from "../theme";
const dateFormater = (dateString) => {
  const date = new Date(dateString); // Current date
  const year = date.getFullYear(); // Get the full year
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Get the month (zero-based, so add 1)
  const day = String(date.getDate()).padStart(2, "0"); // Get the day of the month

  return `${year}-${month}-${day}`;
};

const fetchingData = async (endPoint, setData) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/${endPoint}`);

    setData((prev) => {
      if (!data || data == undefined) return prev;
      return data.data;
    });
  } catch (e) {
    console.log("error fetching data", e);
  }
};

const fetchingDataForChart = async (endPoint, setData) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/${endPoint}`);

    setData((prevdata) => {
      // console.log(prevdata);
      if (data == undefined) return prevdata;
      return [
        {
          id: prevdata[0].id,
          color: prevdata[0].color,
          data: Array.isArray(data.data)
            ? data.data
            : [{ x: "Placeholder", y: 0 }],
        },
      ];
    });
    return data;
  } catch (e) {
    console.log("error fetching data", e);
  }
};
const fetchData = async (endPoint) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/${endPoint}`);
    return data;
  } catch (e) {
    console.log("error fetching data", e);
  }
};


function formatTime(seconds) {
  // Calculate hours, minutes, and remaining seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  // Format the result as "XH:Ym:Zs"
  return `${hours}H:${minutes}m:${remainingSeconds}s`;
}
// const formatDataForChart = (prevdata){
//   return [
//     {
//       id: prevdata[0].id,
//       color: prevdata[0].color,
//       data: Array.isArray(data.data)
//       ? data.data
//       : [{ x: "Placeholder", y: 0 }],
//     },
//   ];

// }

export { dateFormater, fetchingData, fetchingDataForChart, fetchData , formatTime};
