import { Box, Button, Menu, useTheme } from "@mui/material";

// icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MenuItem } from "react-pro-sidebar";
import { useState } from "react";
import { tokens } from "../theme";

const DropDownMenu = ({
  parkings,
  selectedItem,
  handleChanged,
  showAllParkings = true,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    // console.log(e.currentTarget);
    setAnchorEl(null);
  };
  return (
    <Box>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
          minWidth: 180,
        }}
      >
        <ExpandMoreIcon sx={{ mr: "10px" }} />
        {selectedItem.title}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "end",
        }}
        sx={{
          "& .css-1if8r2c-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper":
            {
              backgroundColor: colors.grey[900],
            },
        }}
      >
        {showAllParkings && (
          <MenuItem
            style={{
              width: 180,
              paddingBottom: 10,
              color: colors.grey[100],
              cursor: "pointer",
              textAlign: "center",
              fontSize: 14,
              padding: "5px 10px",
              fontWeight: "bold",
              letterSpacing: 1,
              borderBottom: "1px solid #ffffff3b",
            }}
            onClick={() =>
              handleChanged({ id: 0,size:1800, title: "Tous les parkings" }, setAnchorEl)
            }
          >
            Tous les parkings
          </MenuItem>
        )}
        {parkings.map((parking, i) => (
          <MenuItem
            key={i}
            style={{
              width: 180,
              paddingBottom: 10,
              color: colors.grey[100],
              cursor: "pointer",
              textAlign: "center",
              fontSize: 14,
              padding: "5px 10px",
              fontWeight: "bold",
              letterSpacing: 1,
              borderBottom: "1px solid #ffffff3b",
            }}
            onClick={() => handleChanged(parking, setAnchorEl)}
          >
            {parking.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default DropDownMenu;
