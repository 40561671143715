import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { FaCarSide } from "react-icons/fa";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FaMoneyBill1 } from "react-icons/fa6";
import { BASE_URL } from "../../Constant";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import { useEffect, useState } from "react";
import axios from "axios";
import DropDownMenu from "../../components/DropDownMenu";
import { dateFormater, fetchingData } from "../../Utils/helpers";
import RegisteredCars from "./RegisteredCars";
import InOutChart from "./InOutChart";

import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import OccupationRate from "./OccupationRate";
import useSocket from "../../hooks/useSocket";
import { IoCarSharp } from "react-icons/io5";
import SubscriberListDialog from "./SubscriberListDialog";

const calculate_percentage = (cur_value, prev_value) => {
  if (prev_value == 0) return cur_value % 1;
  if (isNaN(cur_value)) cur_value = 0;
  if (isNaN(prev_value)) prev_value = 0;
  return Math.abs(cur_value / prev_value - 1);
};
const calculate_percentage_compared_to_yesterday = (cur_value, prev_value) => {
  // console.log(typeof cur_value , typeof prev_value);
  if (isNaN(cur_value)) cur_value = 0;
  if (isNaN(prev_value)) prev_value = 0;

  let value = 0;
  if (prev_value != 0) {
    value = (cur_value * 100) / prev_value - 100;
  }

  // console.log(value);
  if (value >= 0) return `+${value.toFixed(2)}%`;
  return `${value.toFixed(2)}%`;
};

const Dashboard = ({ socket }) => {
  // const socket = useSocket("http://98.81.55.222:5001");
  // const socket = useSocket("http://localhost:5000");
  const [open, setOpen] = useState(false);
  const [subscribersDialogType, setSubscribersDialogType] = useState(0);
  const [inOutChartData, setInOutChartData] = useState([]);
  const [hourlyOccupanyRateData, setHourlyOccupanyRateData] = useState([]);
  const [lastmonthSubscriptionRepoData, setLastmonthSubscriptionRepoData] =
    useState([]);
  const [currentParking, setCurrentParking] = useState({
    id: 0,
    title: "Tous les parkings",
  });

  const [currentRevenueParking, setCurrentRevenueParking] = useState({
    id: 0,
    title: "Tous les parkings",
  });

  const [recentRevenu, setRecentRevenu] = useState([]);

  const [parkings, setParkings] = useState([
    // {
    //   id: 0,
    //   title: "Tous les parkings",
    //   size: 1800,
    // },
    {
      id: 1,
      title: "Bab Jdid P1-P2",
      size: 802,
    },
    {
      id: 2,
      title: "Bab Jdid P3",
      size: 105,
    },
    {
      id: 3,
      title: "Ain Azlitan principale",
      size: 165,
    },
    {
      id: 4,
      title: "Ain Azlitan Annex",
      size: 30,
    },
    {
      id: 5,
      title: "Bab Boujloud",
      size: 153,
    },
    {
      id: 6,
      title: "Oued Zhoune",
      size: 127,
    },
    {
      id: 7,
      title: "Bin Lemdoune",
      size: 203,
    },
    {
      id: 8,
      title: "Bab Hamra",
      size: 92,
    },
    {
      id: 9,
      title: "Bab Guissa",
      size: 172,
    },
  ]);

  const [statistics, setStatistics] = useState({
    in_count: 0,
    last_in_count: 0,
    out_count: 0,
    last_out_count: 0,
    today_amount: 0,
    yester_day_amount: 0,
  });

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handlePakringChanged = (Parking, setAnchorEl) => {
    console.log("current parking", Parking);
    setCurrentParking(Parking);
    // fetchingData(
    //   `statistics/global?id=${Parking.id}&date=2024-08-10`,
    //   setStatistics
    // );

    setAnchorEl(null);
    // if (socket) {
    //   socket.emit("dashboard/get-params", Parking);
    // }
  };

  const handleParkingRevenueChanged = (parking, setAnchorEl) => {
    setCurrentRevenueParking(parking);
    // console.log(`statistics/RevenuePerParking?${parking.id}=1&limit=10`);

    fetchingData(
      `statistics/RevenuePerParking?id=${parking.id}&limit=10`,
      setRecentRevenu
    );
    setAnchorEl(null);
  };

  useEffect(() => {
    console.log("====================================");
    console.log(currentParking);
    console.log("====================================");

    setStatistics({
      in_count: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      last_in_count: 0,
      out_count: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      last_out_count: 0,
      today_amount: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      yester_day_amount: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
    });
    // try {
    //   let data = localStorage.getItem("dashboard/get-dashboard-data");
    //   data = JSON.parse(data);
    //   // console.log(data);
    //   setStatistics((prev) => {
    //     if (!data || data == undefined || data?.length == 0) return prev;
    //     return data.data;
    //   });
    // } catch (e) {
    //   console.log("data not exists");
    // }
    // console.log("is undefined", typeof data == undefined);

    if (socket) {
      socket.emit("dashboard/get-params", currentParking);
      // socket.emit("setSession");
      // socket.emit("dashboard/opened", { data: "hello", backendIntervalId });
      socket.on("dashboard/get-dashboard-data", ({ data }) => {
        console.log(data);

        localStorage.setItem(
          "dashboard/get-dashboard-data",
          JSON.stringify(data)
        );
        console.log(data.data);
        setStatistics((prev) => {
          if (!data || data == undefined) return prev;
          return {
            in_count: data.data["in_count"],
            last_in_count: data.data["last_in_count"],
            out_count: data.data["out_count"],
            last_out_count: data.data["last_out_count"],
            today_amount: `${data.data["today_amount"]}DH`,
            yester_day_amount: `${data.data["yester_day_amount"]}DH`,
          };
        });
      });

      socket.on("dashboard/inOutCarFlow", ({ data }) => {
        // console.log('====================================');
        // console.log("dashboard/inOutCarFlow",data);
        console.log("====================================");
        setInOutChartData(data);
        // console.log("Received data from socket:", data);
        // localStorage.setItem("dashboard/inOutCarFlow", JSON.stringify(data));
        // setInOutCarFlow((prev) => {
        //   if (data == undefined) return prev;
        //   return [
        //     {
        //       ...prev[0],
        //       data: Array.isArray(data?.out_data)
        //         ? data.out_data
        //         : [{ x: "Flux entrant", y: 0 }],
        //     },
        //     {
        //       ...prev[1],
        //       data: Array.isArray(data?.in_data)
        //         ? data.in_data
        //         : [{ x: "Flux sortant", y: 0 }],
        //     },
        //   ];
        // });
      });

      socket.on("dashboard/hourlyOccupanyRate", ({ data }) => {
        // console.log("====================================");
        // console.log("data ocuupation rate: ", data);
        // console.log("====================================");
        setHourlyOccupanyRateData(data);
      });

      socket.on("dashboard/lastmonthRepo", ({ data }) => {
        // console.log(data);
        setLastmonthSubscriptionRepoData(data?.data);
      });
    }
    // dashboard/get-dashboard-data
    // fetchingData(`global/getAllParkings`, setParkings);

    fetchingData(`statistics/RevenuePerParking?id=0&limit=10`, setRecentRevenu);
    return () => {
      if (socket) {
        socket.off("dashboard/get-dashboard-data");
      }
    };
    //  ==========================================================
    // statistics ==============
    // fetchingData(`statistics/global?date=2024-08-10`, setStatistics);

    // // getAll Parkings =====================

    // // RevenuePerParking ==================
  }, [socket, currentParking]);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Dashboard" subtitle="Welcome to your dashboard" />

        <Box>
          <DropDownMenu
            parkings={parkings}
            selectedItem={currentParking}
            handleChanged={handlePakringChanged}
          />
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={statistics.in_count}
            subtitle="Nombre d'entrées"
            progress={calculate_percentage(
              statistics.in_count,
              statistics.last_in_count
            )}
            increase={calculate_percentage_compared_to_yesterday(
              statistics.in_count,
              statistics.last_in_count
            )}
            icon={
              <FaCarSide
                style={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={statistics.out_count}
            subtitle="Nombre de sorties"
            progress={calculate_percentage(
              statistics.out_count,
              statistics.last_out_count
            )}
            increase={calculate_percentage_compared_to_yesterday(
              statistics.out_count,
              statistics.last_out_count
            )}
            icon={
              <IoCarSharp
                style={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={statistics.today_amount}
            subtitle="Montant actuel"
            progress={calculate_percentage(
              statistics.today_amount,
              statistics.yester_day_amount
            )}
            increase={calculate_percentage_compared_to_yesterday(
              statistics.today_amount,
              statistics.yester_day_amount
            )}
            icon={
              <FaMoneyBillTrendUp
                style={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={statistics.yester_day_amount}
            subtitle="Montant d'hier"
            progress={calculate_percentage(
              statistics.yester_day_amount,
              statistics.yester_day_amount
            )}
            increase={calculate_percentage_compared_to_yesterday(
              statistics.yester_day_amount * 2,
              statistics.yester_day_amount
            )}
            icon={
              <FaMoneyBill1
                style={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <InOutChart inOutData={inOutChartData} />
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              The Privious 10 Days
            </Typography>
            <DropDownMenu
              parkings={parkings}
              selectedItem={currentRevenueParking}
              handleChanged={handleParkingRevenueChanged}
              // showAllParkings={false}
            />
          </Box>
          {recentRevenu.map((revenue, i) => (
            <Box
              key={i}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {dateFormater(revenue.charge_time)}
                  {/* {revenue.charge_time} */}
                </Typography>
                <Typography fontSize={10} color={colors.grey[100]}>
                  {currentRevenueParking.title}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{revenue.exit_count}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                {revenue.revenue}DH
              </Box>
            </Box>
          ))}
        </Box>

        {/* ROW 3 */}
        {/* <InOutChart /> */}
        <OccupationRate hourlyOccupanyRateData={hourlyOccupanyRateData} />
        {/* <DataSaverOnIcon style={{fontSize:"100px"}}/> */}
        <RegisteredCars
          lastmonthSubscriptionRepoData={lastmonthSubscriptionRepoData}
          setOpen={setOpen}
          setSubscribersDialogType={setSubscribersDialogType}
        />
      </Box>

      <SubscriberListDialog
        open={open}
        handleClickOpen={setOpen}
        handleClose={setOpen}
        subscribersDialogType={subscribersDialogType}
      />
    </Box>
  );
};

export default Dashboard;
