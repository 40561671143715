import { Authenticator, Placeholder } from "@aws-amplify/ui-react";
import { Box } from "@mui/material";
import { Amplify } from "aws-amplify";

import "@aws-amplify/ui-react/styles.css";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID || "",
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID || "",
    },
  },
});

const formFeild = {
  signUp: {
    username: {
      order: 1,
      placeholder: "choose your username",
      label: "Username",
      inputProps: { required: true },
    },
    email: {
      order: 2,
      placeholder: "Enter Your Email Address",
      label: "Email",
      inputProps: { type: "email", required: true },
    },
    password: {
      order: 3,
      placeholder: "Enter Your Password",
      label: "Password",
      inputProps: { type: "password", required: true },
    },
    confirm_password: {
      order: 4,
      placeholder: "Confirm Your Password",
      label: "Confrim Password",
      inputProps: { type: "password", required: true },
    },
  },
};
const AuthProvider = ({ children }) => {
  return (
    <Box mt={20}>
      <Authenticator hideSignUp formFields={formFeild}>
        {({ user }) =>
          user ? (
            <Box mt={-20}>{children}</Box>
          ) : (
            <Box>Please Sign In Below</Box>
          )
        }
      </Authenticator>
    </Box>
  );
};

export default AuthProvider;
