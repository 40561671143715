import { useEffect, useState } from "react";
import { io } from "socket.io-client";

const useSocket = (url) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socketInstance = io(url);

    socketInstance.on("connect", () => {
      console.log("Connected to the server");
    });

    socketInstance.on("disconnect", () => {
      console.log("Disconnected from the server");
    });

    socketInstance.on("connect_error", (err) => {
      console.error("Connection error:", err);
    });

    setSocket(socketInstance);

    // Clean-up
    return () => {
      // socketInstance.emit("disconnect");
      socketInstance.off("connect");
      socketInstance.off("disconnect");
      socketInstance.off("connect_error");
      socketInstance.disconnect();
    };
  }, [url]);

  return socket;
};

export default useSocket;
