import {
  Alert,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Menu,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { FaCarSide } from "react-icons/fa";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FaMoneyBill1 } from "react-icons/fa6";

import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import { useEffect, useState } from "react";
import axios from "axios";
import DropDownMenu from "../../components/DropDownMenu";
import { dateFormater, fetchingData } from "../../Utils/helpers";
import RegisteredCars from "./RegisteredCars";
import InOutChart from "./InOutChart";

import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import OccupationRate from "./OccupationRate";
import useSocket from "../../hooks/useSocket";

import Slide from "@mui/material/Slide";

const calculate_percentage = (cur_value, prev_value) => {
  if (prev_value == 0) return cur_value % 1;

  return Math.abs(cur_value / prev_value - 1);
};
const calculate_percentage_compared_to_yesterday = (cur_value, prev_value) => {
  // console.log(typeof cur_value , typeof prev_value);

  let value = 0;
  if (prev_value != 0) {
    value = (cur_value * 100) / prev_value - 100;
  }

  // console.log(value);
  if (value >= 0) return `+${value.toFixed(2)}%`;
  return `${value.toFixed(2)}%`;
};

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}
const DashboardWithSocket = () => {
  const socket = useSocket("http://localhost:5000");
  const [messages, setMessages] = useState(["Dashboard"]);
  const [messageType, setMessageType] = useState(1);
  const [input, setInput] = useState("");
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  const [currentParking, setCurrentParking] = useState({
    id: 0,
    title: "All Parkings",
  });

  const [currentRevenueParking, setCurrentRevenueParking] = useState({
    id: 0,
    title: "All Parkings",
  });

  const [recentRevenu, setRecentRevenu] = useState([]);

  const [parkings, setParkings] = useState([
    {
      id: 0,
      title: "All Parkings",
    },
  ]);

  const [statistics, setStatistics] = useState({
    in_count: 0,
    last_in_count: 0,
    out_count: 0,
    last_out_count: 0,
    today_amount: 0,
    yester_day_amount: 0,
  });

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handlePakringChanged = (Parking, setAnchorEl) => {
    setCurrentParking(Parking);
    fetchingData(
      `statistics/global?id=${Parking.id}&date=2024-08-10`,
      setStatistics
    );
    setAnchorEl(null);
  };

  const handleParkingRevenueChanged = (parking, setAnchorEl) => {
    setCurrentRevenueParking(parking);
    console.log(parking.id);
    console.log(`statistics/RevenuePerParking?${parking.id}=1&limit=10`);

    fetchingData(
      `statistics/RevenuePerParking?id=${parking.id}&limit=10`,
      setRecentRevenu
    );
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   let timer = setInterval(() => {
  //     setProgress((oldProgress) => {
  //       if (oldProgress === 100) {
  //         return 0;
  //       }
  //       // const diff = Math.random() * 10;
  //       return oldProgress + 2;
  //     });
  //   }, 100);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  // useEffect(() => {
  //   // statistics ==============
  //   // fetchingData(`statistics/global?date=2024-08-10`, setStatistics);

  //   // // getAll Parkings =====================
  //   // fetchingData(`global/getAllParkings`, setParkings);

  //   // // RevenuePerParking ==================
  //   // fetchingData(`statistics/RevenuePerParking?id=1&limit=10`, setRecentRevenu);
  //   // console.log("====================================");
  //   // console.log("hello");
  //   // console.log("====================================");
  //   if (socket) {
  //     console.log("inside sockets =========================");
  //     // socket.on("get-data", (msg) => {
  //     //   setMessageType(msg.index);
  //     //   setMessages((prev) => {
  //     //     return [...prev, msg.message];
  //     //   });
  //     //   setOpen(true);
  //     // });

  //     socket.on("dashboard/inOutCarFlow", (data) => {
  //       console.log(data.data);
  //     });
  //   }

  //   // return () => {
  //   //   socket.off("dashboard/get-dashboard-data");
  //   // };

  //   // return () => {
  //   //   if (socket) {
  //   //     socket.off("dashboard/inOutCarFlow");
  //   //   }
  //   // };
  // }, []);

  useEffect(() => {
    if (socket) {
      console.log("Socket connected, setting up event listeners...");

      // Listen for the "git-data" event
      socket.on("dashboard/inOutCarFlow", ({data}) => {
        console.log("Received data from socket:", data.data);
      });

      // Clean up the socket listeners when the component unmounts or the socket changes
    }
    return () => {
      if (socket) {
        console.log("Cleaning up socket listeners...");
        socket.off("dashboard/inOutCarFlow");
      }
    };
  }, [socket]);

  // useEffect(() => {
  //   console.log("jkgufggufi");

  //   return () => {
  //     if (socket) {

  //       socket.emit("clear");
  //     }
  //   };
  // }, []);

  const sendMessage = () => {
    if (input.trim()) {
      socket.emit("clear", input);
      setInput("");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Box m="20px">
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={messageType % 2 == 0 ? "success" : "error"}
          style={{ color: "white" }}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {messages[messages.length - 1]}
        </Alert>
      </Snackbar>
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title={messages[messages.length - 1]}
          subtitle="Welcome to your dashboard"
        />

        <Box>
          <DropDownMenu
            parkings={parkings}
            selectedItem={currentParking}
            handleChanged={handlePakringChanged}
          />
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <button onClick={sendMessage}>Send</button>
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={statistics.in_count}
            subtitle="In Count"
            progress={calculate_percentage(
              statistics.in_count,
              statistics.last_in_count
            )}
            increase={calculate_percentage_compared_to_yesterday(
              statistics.in_count,
              statistics.last_in_count
            )}
            icon={
              <FaCarSide
                style={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={statistics.out_count}
            subtitle="Exit Count"
            progress={calculate_percentage(
              statistics.out_count,
              statistics.last_out_count
            )}
            increase={calculate_percentage_compared_to_yesterday(
              statistics.out_count,
              statistics.last_out_count
            )}
            icon={
              <RiLogoutBoxRLine
                style={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={`${statistics.today_amount}DH`}
            subtitle="Current Amount"
            progress={calculate_percentage(
              statistics.today_amount,
              statistics.yester_day_amount
            )}
            increase={calculate_percentage_compared_to_yesterday(
              statistics.today_amount,
              statistics.yester_day_amount
            )}
            icon={
              <FaMoneyBillTrendUp
                style={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={`${statistics.yester_day_amount}DH`}
            subtitle="YesterDay Amount"
            progress={calculate_percentage(
              statistics.yester_day_amount,
              statistics.yester_day_amount
            )}
            increase={calculate_percentage_compared_to_yesterday(
              statistics.yester_day_amount * 2,
              statistics.yester_day_amount
            )}
            icon={
              <FaMoneyBill1
                style={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <InOutChart />
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              The Privious 10 Days
            </Typography>
            <DropDownMenu
              parkings={parkings}
              selectedItem={currentRevenueParking}
              handleChanged={handleParkingRevenueChanged}
              showAllParkings={false}
            />
          </Box>
          {recentRevenu.map((revenue, i) => (
            <Box
              key={i}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {dateFormater(revenue.charge_time)}
                  {/* {revenue.charge_time} */}
                </Typography>
                <Typography fontSize={10} color={colors.grey[100]}>
                  {currentRevenueParking.title}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{revenue.exit_count}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                {revenue.revenue}DH
              </Box>
            </Box>
          ))}
        </Box>

        {/* ROW 3 */}
        {/* <InOutChart /> */}
        <OccupationRate />
        {/* <DataSaverOnIcon style={{fontSize:"100px"}}/> */}
        <RegisteredCars />
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          // padding="30px"
        >
          <LinearProgress
            variant="determinate"
            value={progress}
            style={{ color: "#3687D8", backgroundColor: "white" }}
            // backgroundColor="red"
            color="inherit"
            maxHeight={100}
          />
          <Box
            padding="30px"
            display="flex"
            flexDirection="column"
            gap={1}
            overflow="auto"
            maxHeight="-webkit-fill-available"
          >
            {messages.map((item, i) => {
              return <Box key={i}>{item}</Box>;
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardWithSocket;
