import { Box, Typography } from "@mui/material";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import DropDownMenu from "../../components/DropDownMenu";
import { useEffect, useState } from "react";
import {
  dateFormater,
  fetchingData,
  fetchingDataForChart,
} from "../../Utils/helpers";
import PdfTemplate from "../../components/PdfTemplate";
import LineChart from "../../components/LineChart";
import CustomTabs from "../../components/Tabs";
// import  '@react-pdf/renderer'
// ===================================================
import { FaCarSide } from "react-icons/fa";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FaMoneyBill1 } from "react-icons/fa6";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { mockDataContacts } from "../../data/mockData";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import BarChart from "../../components/BarChart";

const GlobalOccupationRate = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [basicMonthlyStatistices, setBasicMonthlyStatistices] = useState([]);
  const [
    revenuForTheDaysOFTheCurrentMonth,
    setRevenuForTheDaysOFTheCurrentMonth,
  ] = useState([
    {
      id: "Revenu Of The Month",
      color: colors.blueAccent[200],
      data: [],
    },
  ]);
  const [parkings, setParkings] = useState([]);
  const tabs = [
    {
      id: 0,
      title: "This Month",
    },
    {
      id: 1,
      title: "Pervious Month",
    },
  ];

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "in_count", headerName: "Entry Count" },
    {
      field: "out_count",
      headerName: "Exit Count",
      //   flex: 1,
      //   cellClassName: "name-column--cell",
    },
    {
      field: "out_amount",
      headerName: "Reguler Amount",
      flex: 1.1,
      //   type: "number",
      //   headerAlign: "left",
      //   align: "left",
    },
    {
      field: "pt_count",
      headerName: "PT Count",
      flex: 1,
    },
    {
      field: "pt_days",
      headerName: "PT Days",
      flex: 1,
    },
    {
      field: "pt_amount",
      headerName: "PT Amount",
      flex: 1,
    },
    {
      field: "mt_count",
      headerName: "MT count",
      flex: 1,
    },
    {
      field: "mt_amount",
      headerName: "MT Amount",
      flex: 1,
    },
    {
      field: "date_time",
      headerName: "Date",
      flex: 1,
      valueGetter: (value) => {
        return dateFormater(value.value);
      },
    },
    {
      headerName: "Print",
      flex: 1.5,
      renderCell: (row) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={colors.greenAccent[600]}
            borderRadius="4px"
          >
            <PictureAsPdfIcon color="white" />
            <Typography color="white" sx={{ ml: "5px" }}>
              Print
            </Typography>

            {/* <PDFDownloadLink document={<PdfTemplate />} fileName="example.pdf">
                {({ blob, url, loading, error }) =>
                    loading ? 'Loading document...' : 'Download PDF'
                }
            </PDFDownloadLink> */}
          </Box>
        );
      },
    },
    //   renderCell:({row: {date_time}})=>{
    //     ret
    //   }
    //   renderCell: ({ row: { access } }) => {
    //     return (
    //       <Box
    //         width="60%"
    //         m="0 auto"
    //         p="5px"
    //         display="flex"
    //         justifyContent="center"
    //         backgroundColor={
    //           access === "admin"
    //             ? colors.greenAccent[600]
    //             : access === "manager"
    //             ? colors.greenAccent[700]
    //             : colors.greenAccent[700]
    //         }
    //         borderRadius="4px"
    //       >
    //         {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
    //         {access === "manager" && <SecurityOutlinedIcon />}
    //         {access === "user" && <LockOpenOutlinedIcon />}
    //         <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
    //           {access}
    //         </Typography>
    //       </Box>
    //     );
    //   },
    // },
  ];

  const handleMonthChange = (e) => {
    let month_id = e.target.id;
    fetchingDataForChart(
      `report/revenuForTheDaysOFTheCurrentMonth?prev_month=${month_id}`,
      setRevenuForTheDaysOFTheCurrentMonth
    );
  };

  const handlePakringChanged = (Parking, setAnchorEl) => {
    setCurrentParking(Parking);
    setAnchorEl(null);
  };
  const [currentParking, setCurrentParking] = useState({
    id: 1,
    title: "Bab Jdid P1-P2",
  });

  useEffect(() => {
    fetchingData("report/BasicMonthlyStatistices", setBasicMonthlyStatistices);
    fetchingDataForChart(
      "report/revenuForTheDaysOFTheCurrentMonth",
      setRevenuForTheDaysOFTheCurrentMonth
    );
    // fetchingData("report/daily?date=2024-08-01", setMonth);
    // getAll Parkings =====================
    fetchingData(`global/getAllParkings`, setParkings);
  }, []);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Taux D'occupation Global"
          subtitle="Le pourcentage de places de stationnement actuellement occupées dans un parking."
        />

        <Box>
          <DropDownMenu
            parkings={parkings}
            selectedItem={currentParking}
            handleChanged={handlePakringChanged}
          />
        </Box>

        {/* <PDFDownloadLink document={<PdfTemplate />} fileName="example.pdf">
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Download PDF"
          }
        </PDFDownloadLink> */}
      </Box>



      <Box display="flex" justifyContent="flex-end" mt={3}>
        <CustomTabs tabs={tabs} handleTabsClick={handleMonthChange} />
      </Box>
      <Box
        // gridColumn="span 8"
        display="flex"
        // gridRow="span 2"
        // backgroundColor={colors.primary[100]}
        // mt={1}
      >
        <Box height="350px" m="-20px 0 0 0" flex={2}>
          <LineChart
            params={{
              left_title: "Revenu Total (DH)",
              bottom_title: "Date",
            }}
            dataP={revenuForTheDaysOFTheCurrentMonth}
          />
        </Box>
        <Box height="350px" flex={1}>
          <BarChart isDashboard={true} />
        </Box>
        {/* <Box display="flex">
          <Box height="350px" flex={2}>
            <BarChart />
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default GlobalOccupationRate;
