import { Box, Typography } from "@mui/material";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import DropDownMenu from "../../components/DropDownMenu";
import { useEffect, useState } from "react";
import { dateFormater, fetchingData } from "../../Utils/helpers";
import PdfTemplate from "../../components/PdfTemplate";
// import  '@react-pdf/renderer'
// ===================================================
import { FaCarSide } from "react-icons/fa";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FaMoneyBill1 } from "react-icons/fa6";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { mockDataContacts } from "../../data/mockData";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { IoCarSharp } from "react-icons/io5";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { MdSsidChart } from "react-icons/md";
import StatBoxExtend from "../../components/StatBoxExtend";
import dayjs from "dayjs";

const LiveSessions = ({ socket }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [dailyReport, setDailyReport] = useState([]);
  const [parkings, setParkings] = useState([]);
  const [currentLiveSession, setCurrentLiveSession] = useState([]);
  const handlePakringChanged = (Parking, setAnchorEl) => {
    setCurrentParking(Parking);
    setAnchorEl(null);
  };
  const [currentParking, setCurrentParking] = useState({
    id: 1,
    title: "Bab Jdid P1-P2",
  });

  // useEffect(() => {
  //   fetchingData("report/daily?date=2024-08-01", setDailyReport);
  //   // getAll Parkings =====================
  //   fetchingData(`global/getAllParkings`, setParkings);
  // }, []);
  useEffect(() => {
    if (socket) {
      socket.emit("Sessions/start");

      socket.on("Sessions/liveSessions", ({ data }) => {
        data = data?.data;
        let currentLiveData = [];
        data?.map((item, i) => {
          currentLiveData.push({
            id: i,
            parking_name: item?.parking_name || "",
            username: item?.auth_user_name || "",
            start_time: item?.start_time || dayjs().format("YYYY-MM-D"),
            in_count: item?.in_count || 0,
            out_count: item?.out_count || 0,
            normal_amount: item?.normal_amount || 0,
            mt_count: item?.manuel_count || 0,
            mt_amount: item?.manuel_amount || 0,
            pt_count: item?.penalty_count || 0,
            pt_amount: item?.penalty_amount || 0,
          });
        });
        console.log("====================================");
        console.log("Sessions/liveSessions", currentLiveData);
        console.log("====================================");

        setCurrentLiveSession(currentLiveData);
      });
    }
  }, [socket]);
  const columns = [
    { field: "parking_name", headerName: "Parking Name" },
    {
      field: "username",
      headerName: "Username",
      //   flex: 1,
      //   cellClassName: "name-column--cell",
    },
    { field: "start_time", headerName: "Début De Session" },
    {
      field: "in_count",
      headerName: "Nombre D'entrées",
      flex: 1.1,
      //   type: "number",
      //   headerAlign: "left",
      //   align: "left",
    },
    {
      field: "out_count",
      headerName: "Nombre De Sorties",
      flex: 1,
    },
    {
      field: "normal_amount",
      headerName: "Montant normal",
      flex: 1,
    },
    {
      field: "pt_count",
      headerName: "N. Ticket perdu",
      flex: 1,
    },
    {
      field: "pt_amount",
      headerName: "TP Montant",
      flex: 1,
    },
    {
      field: "mt_count",
      headerName: "N. Ticket Manuel",
      flex: 1,
    },
    {
      field: "mt_amount",
      headerName: "TM Montant",
      flex: 1,
      // valueGetter: (value) => {
      //   return dateFormater(value.value);
      // },
    },
    {
      field: "id",
      headerName: "Total Amount",
      flex: 1,
      renderCell: (row) => {
        row = row.row;
        console.log("====================================");
        console.log("row", row);
        console.log("====================================");
        return (
          <span>
            {parseInt(row.pt_amount) +
              parseInt(row.normal_amount) +
              parseInt(row.mt_amount)}
            DH
          </span>
        );
      },
    },
    // {
    //   headerName: "Montant total",
    //   flex: 1.5,
    // valueGetter: (value) => {
    //   return dateFormater(value.value);
    // },
    // renderCell: (row) => {
    //   row = row.row;
    //   return (
    //     <span>
    //       {parseInt(row.pt_amount) +
    //         parseInt(row.normal_amount) +
    //         parseInt(row.mt_amount)}
    //       DH
    //     </span>
    //   );
    // },
    // },
    //   renderCell:({row: {date_time}})=>{
    //     ret
    //   }
    //   renderCell: ({ row: { access } }) => {
    //     return (
    //       <Box
    //         width="60%"
    //         m="0 auto"
    //         p="5px"
    //         display="flex"
    //         justifyContent="center"
    //         backgroundColor={
    //           access === "admin"
    //             ? colors.greenAccent[600]
    //             : access === "manager"
    //             ? colors.greenAccent[700]
    //             : colors.greenAccent[700]
    //         }
    //         borderRadius="4px"
    //       >
    //         {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
    //         {access === "manager" && <SecurityOutlinedIcon />}
    //         {access === "user" && <LockOpenOutlinedIcon />}
    //         <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
    //           {access}
    //         </Typography>
    //       </Box>
    //     );
    //   },
    // },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Live Session"
          subtitle="Les séances sont actuellement ouvertes dans les parkings"
        />

        {/* <Box>
          <DropDownMenu
            parkings={parkings}
            selectedItem={currentParking}
            handleChanged={handlePakringChanged}
          />
        </Box> */}

        {/* <PDFDownloadLink document={<PdfTemplate />} fileName="example.pdf">
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Download PDF"
          }
        </PDFDownloadLink> */}
      </Box>

      <Box
        height="74vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={currentLiveSession}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default LiveSessions;
