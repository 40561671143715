import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useEffect } from "react";
import { dateFormater, fetchingData } from "../../Utils/helpers";

const SubscriberListDialog = ({
  open,
  handleClickOpen,
  handleClose,
  subscribersDialogType,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [subscribersList, setSubscribersList] = useState([]);

  useEffect(() => {
    fetchingData(`subscription/SubscribersStateList`, setSubscribersList);
    console.log("====================================");
    console.log(subscribersList);
    console.log("====================================");
  }, [subscribersDialogType]);

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={() => handleClickOpen(true)}>
        Open form dialog
      </Button>
      <Dialog
        sx={{
          width: "70 vw",
          "& form": {
            backgroundColor: "white",
            color: "black",
            maxWidth: "1000px !important",
            width: "1000px",
          },

          "& input": {
            borderBottom: "1px solid black",
            color: "black",
          },
          "& form label": {
            color: "black !important",
          },
        }}
        open={open}
        onClose={() => handleClose(false)}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose(false);
          },
        }}
      >
        <DialogTitle>Add Mail To Receive Mail</DialogTitle>
        <DialogContent sx={{ maxWidth: "70 vw !important" }}>
          <DialogContentText
            sx={{
              color: "black !important",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 1
            }}
          >
            <Box>
              To subscribe to this website, please enter your email address
              here. We will send updates occasionally.
            </Box>
            <Input sx={{ border: "1px solid black"}} placeholder="Search...."/>
          </DialogContentText>

          <TableContainer
          // backgroundColor={colors.blueAccent[900]}
          // sx={{ padding: 3 }}
          >
            <Table
              sx={{
                // minWidth: 650,
                color: colors.blueAccent[900],
                border: "1px solid",
                // margin: 3,
              }}
              aria-label="simple table"
            >
              <TableBody>
                <TableRow
                  key={3}
                  //   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: colors.blueAccent[900],
                    }}
                  >
                    {"Full Name"}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: colors.blueAccent[900],
                    }}
                  >
                    {"Pin"}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: colors.blueAccent[900],
                    }}
                  >
                    {"Vehicle Number"}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: colors.blueAccent[900],
                    }}
                  >
                    {"Phone Number"}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: colors.blueAccent[900],
                    }}
                  >
                    {"Start Time"}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: colors.blueAccent[900],
                    }}
                  >
                    {"End Time"}
                  </TableCell>

                  {/* <TableCell sx={{ color: colors.blueAccent[900] }}>
                    {0}
                  </TableCell> */}
                </TableRow>
                {subscribersList.map((item) => {
                  return (
                    <TableRow>
                      <TableCell sx={{ color: colors.blueAccent[900] }}>
                        {item.name} {item.last_name}
                      </TableCell>
                      <TableCell sx={{ color: colors.blueAccent[900] }}>
                        {item.pin}
                      </TableCell>
                      <TableCell sx={{ color: colors.blueAccent[900] }}>
                        {item.vehicle_cardnumber}
                      </TableCell>
                      <TableCell sx={{ color: colors.blueAccent[900] }}>
                        {item.phone_number}
                      </TableCell>
                      <TableCell sx={{ color: colors.blueAccent[900] }}>
                        {dateFormater(item.strat_time)}
                      </TableCell>
                      <TableCell sx={{ color: colors.blueAccent[900] }}>
                        {dateFormater(item.end_time)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>Cancel</Button>
          {/* <Button type="submit">Subscribe</Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default SubscriberListDialog;
