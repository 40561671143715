import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import CaissiersList from "./scenes/CaissiersList";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import DailyReport from "./scenes/DailyReport";
import MonthlyReport from "./scenes/MonthlyReport";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import DashboardWithSocket from "./scenes/dashboard/indexwithsocket";
import SubscribersLiveData from "./scenes/Subscribers";
import SubscribersList from "./scenes/Subscribers/SubscribersList";
import LiveSessions from "./scenes/SessionManagment/LiveSessions";
import TicketZ from "./scenes/SessionManagment/TicketZ";
import GlobalOccupationRate from "./scenes/GlobalOccupationRate";
import SendMail from "./scenes/SendMail";
import GeneratePdf from "./components/PdfTemplate";
import TEST from "./scenes/dashboard/test";
import useSocket from "./hooks/useSocket";
import { BASE_SOCKET_URL } from "./Constant";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [contentLeftMargin, setContentLeftMargin] = useState(true);
  const socket = useSocket(BASE_SOCKET_URL);
  const isSideBarCollapsed = (isTrue) => {
    if (!isTrue) {
      setContentLeftMargin(270);
    } else {
      setContentLeftMargin(75);
    }
  };
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar
            isSidebar={isSidebar}
            isSideBarCollapsed={isSideBarCollapsed}
          />
          <main className="content" style={{ marginLeft: contentLeftMargin }}>
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              <Route path="/" element={<Dashboard socket={socket} />} />
              <Route path="/caissiers-list" element={<CaissiersList />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/form" element={<Form />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/line" element={<Line />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/dailyReport" element={<DailyReport socket={socket}/>} />
              <Route path="/monthlyReport" element={<MonthlyReport socket={socket} />} />
              <Route path="/socket" element={<DashboardWithSocket />} />
              <Route
                path="/subscribersLiveData"
                element={<SubscribersLiveData socket={socket}/>}
              />
              <Route path="/subscribersList" element={<SubscribersList />} />
              <Route path="/livesessions" element={<LiveSessions socket={socket}/>} />
              <Route path="/billanz" element={<TicketZ />} />
              <Route
                path="/global-occupationRate"
                element={<GlobalOccupationRate />}
              />
              <Route path="/sendmail" element={<SendMail />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
