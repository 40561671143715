import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import DropDownMenu from "../../components/DropDownMenu";
import { useEffect, useState } from "react";
import { dateFormater, fetchingData, formatTime } from "../../Utils/helpers";
import PdfTemplate from "../../components/PdfTemplate";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// import  '@react-pdf/renderer'
// ===================================================
import { FaCarSide } from "react-icons/fa";
import { FaMoneyBill1 } from "react-icons/fa6";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { TbTicketOff } from "react-icons/tb";
import { BsFillTicketDetailedFill } from "react-icons/bs";
import { IoCarSharp } from "react-icons/io5";
import StatBoxExtend from "../../components/StatBoxExtend";
import GeneratePdf from "../../components/PdfTemplate";
import RevenueChart from "./RevenuChart";
import DailyTable from "./DailyTable";
import dayjs from "dayjs";
import {
  StatisticesReport,
  DailyReportPdf,
  Rapport_Tickets_par_Types,
} from "../../components/Pdf Templates/statisticesRep";

const DailyReport = ({ socket }) => {
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  const colors = tokens(theme.palette.mode);
  const [dailyReport, setDailyReport] = useState([]);
  const [restDataOfTheTable, setRestDataOfTheTable] = useState({
    avrage_occupation_rate: 0,
    free_tickets: 0,
    avrage_time: 0,
    morning_entry_count: 0,
    morning_exit_count: 0,
    subscribers_entry_count: 0,
    subscribers_exit_count: 0,
    prev_day_amount: 0,
  });
  const [currentDayReport, setCurrentDayReport] = useState({
    id: false,
    out_count: false,
    out_amount: false,
    pt_count: false,
    pt_days: false,
    pt_amount: false,
    mt_count: false,
    mt_amount: false,
  });
  const [ticketByTypes, setTicketsByTypes] = useState({
    free_cars: "0|0",
    count_between_2_and_3: "0|0",
    count_between_4_and_5: "0|0",
    count_between_6_and_60: "0|0",
    count_between_61_and_120: "0|0",
    count_between_121_and_180: "0|0",
    more_than_180: "0|0",
  });
  const [currentParking, setCurrentParking] = useState({
    id: 0,
    title: "Tous les parkings",
    size: 1800,
  });
  const [parkings, setParkings] = useState([]);
  const [revenueOfSpecificDay, setRevenueOfSpecificDay] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handlePakringChanged = (Parking, setAnchorEl) => {
    console.log(Parking);
    setCurrentParking(Parking);

    setAnchorEl(null);
  };

  useEffect(() => {
    // get all days report for the datatable from the date='choose a date' until now
    console.log(dayjs(selectedDate));
    setCurrentDayReport({
      id: 0,
      in_count: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      out_count: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      out_amount: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      pt_count: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      pt_days: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      pt_amount: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      mt_count: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      mt_amount: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
    });

    setRestDataOfTheTable({
      avrage_occupation_rate: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      free_tickets: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      avrage_time: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      morning_entry_count: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      morning_exit_count: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      subscribers_entry_count: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      subscribers_exit_count: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      prev_day_amount: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
    });
    // console.log({
    //   parking_id: currentParking.id,
    //   date: selectedDate.format("YYYY-MM-D"),
    // });

    if (socket) {
      socket.emit("DailyReport/start", {
        parking_id: currentParking.id,
        date: selectedDate.format("YYYY-MM-D"),
      });
      socket.on("DailyReport/ThisDayReport", ({ data }) => {
        data = data?.data[0] || [];
        setCurrentDayReport({
          id: parseInt(data?.id) || 0,
          in_count: parseInt(data?.in_count) || 0,
          out_count: parseInt(data?.out_count) || 0,
          out_amount: `${parseInt(data?.out_amount) || 0}DH`,
          pt_count: parseInt(data?.pt_count) || 0,
          pt_days: parseInt(data?.pt_days) || 0,
          pt_amount: `${parseInt(data?.pt_amount) || 0}DH`,
          mt_count: parseInt(data?.mt_count) || 0,
          mt_amount: `${parseInt(data?.mt_amount) || 0}DH`,
        });
      });

      socket.on("DailyReport/revenueOfSpecificDay", ({ data }) => {
        // console.log(data.data);
        setRevenueOfSpecificDay(data.data);
      });

      socket.on("DailyReport/getTableData", ({ data }) => {
        setRestDataOfTheTable((prev) => ({
          ...prev,
          avrage_occupation_rate: 0,
        }));
        data.data.map((item) => {
          setRestDataOfTheTable((prev) => {
            return {
              ...prev,
              avrage_occupation_rate:
                prev.avrage_occupation_rate + parseInt(item.y),
            };
          });
        });
      });

      socket.on("DailyReport/dailyTableReport", ({ data }) => {
        setRestDataOfTheTable((prev) => ({
          ...prev,
          free_tickets: parseInt(data.data[0]?.free_tickets || 0),
          avrage_time: formatTime(data.data[0]?.total_time || 0) || 0,
          morning_entry_count: parseInt(data.data[0]?.morning_entry || 0),
          morning_exit_count: parseInt(data.data[0]?.morning_exit || 0),
          subscribers_entry_count: data.data[0]?.subscribers_entry_count || 0,
          subscribers_exit_count: data.data[0]?.subscribers_exit_count || 0,
          prev_day_amount: `${parseInt(data.data[0]?.prev_day_amount || 0)}DH`,
        }));
      });
      socket.on("DailyReport/ticketByTypeReport", ({ data }) => {
        console.log("====================================");
        console.log("tickets By Types", data.data[0]);
        console.log("====================================");
        setTicketsByTypes((prev) => ({
          free_cars: data.data[0]?.free_cars || "0|0",
          count_between_2_and_3: data.data[0]?.count_between_2_and_3 || "0|0",
          count_between_4_and_5: data.data[0]?.count_between_4_and_5 || "0|0",
          count_between_6_and_60: data.data[0]?.count_between_6_and_60 || "0|0",
          count_between_61_and_120:
            data.data[0]?.count_between_61_and_120 || "0|0",
          count_between_121_and_180:
            data.data[0]?.count_between_121_and_180 || "0|0",
          more_than_180: data.data[0]?.more_than_180 || "0|0",
        }));
      });
    }
    // console.log("====================================");
    // console.log(dayjs().subtract(10, "day").format("YYYY-MM-DD"));
    // console.log("====================================");
    fetchingData(
      `report/daily?date=${dayjs().subtract(10, "day").format("YYYY-MM-DD")}`,
      setDailyReport
    );
    // getAll Parkings =====================
    fetchingData(`global/getAllParkings`, setParkings);

    return () => {
      if (socket) {
        socket.off("DailyReport/ThisDayReport");
      }
    };
  }, [socket, currentParking, selectedDate]);

  const columns = [
    { field: "in_count", headerName: "Entry Count" },
    {
      field: "out_count",
      headerName: "Exit Count",
      //   flex: 1,
      //   cellClassName: "name-column--cell",
    },
    {
      field: "out_amount",
      headerName: "Reguler Amount",
      flex: 1.1,
      valueGetter: (value) => `${value.value}DH`,
      //   type: "number",
      //   headerAlign: "left",
      //   align: "left",
    },
    {
      field: "pt_count",
      headerName: "PT Count",
      flex: 1,
    },
    {
      field: "pt_days",
      headerName: "PT Days",
      flex: 1,
    },
    {
      field: "pt_amount",
      headerName: "PT Amount",
      flex: 1,
      valueGetter: (value) => `${value.value}DH`,
    },
    {
      field: "mt_count",
      headerName: "MT count",
      flex: 1,
    },
    {
      field: "mt_amount",
      headerName: "MT Amount",
      flex: 1,
      valueGetter: (value) => `${value.value}DH`,
    },
    {
      field: "date_time",
      headerName: "Date",
      flex: 1,
      valueGetter: (value) => {
        return dateFormater(value.value);
      },
    },
    {
      field: "id",
      headerName: "Total Amount",
      flex: 1,
      renderCell: (row) => {
        row = row.row;
        return (
          <span>
            {parseInt(row.pt_amount) +
              parseInt(row.out_amount) +
              parseInt(row.mt_amount)}
            DH
          </span>
        );
      },
    },
    {
      headerName: "Print",
      flex: 1.5,
      renderCell: (row) => {
        return (
          <GeneratePdf data={row} />
          // <Box
          //   width="60%"
          //   m="0 auto"
          //   p="5px"
          //   display="flex"
          //   justifyContent="center"
          //   backgroundColor={colors.greenAccent[600]}
          //   borderRadius="4px"
          // >
          //   <PictureAsPdfIcon color="white" />
          //   <Typography color="white" sx={{ ml: "5px" }}>
          //     Print
          //   </Typography>

          //   {/* <PDFDownloadLink document={<PdfTemplate />} fileName="example.pdf">
          //       {({ blob, url, loading, error }) =>
          //           loading ? 'Loading document...' : 'Download PDF'
          //       }
          //   </PDFDownloadLink> */}
          // </Box>
        );
      },
    },
    //   renderCell:({row: {date_time}})=>{
    //     ret
    //   }
    //   renderCell: ({ row: { access } }) => {
    //     return (
    //       <Box
    //         width="60%"
    //         m="0 auto"
    //         p="5px"
    //         display="flex"
    //         justifyContent="center"
    //         backgroundColor={
    //           access === "admin"
    //             ? colors.greenAccent[600]
    //             : access === "manager"
    //             ? colors.greenAccent[700]
    //             : colors.greenAccent[700]
    //         }
    //         borderRadius="4px"
    //       >
    //         {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
    //         {access === "manager" && <SecurityOutlinedIcon />}
    //         {access === "user" && <LockOpenOutlinedIcon />}
    //         <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
    //           {access}
    //         </Typography>
    //       </Box>
    //     );
    //   },
    // },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box
        style={{
          // display:""
          position: "absolute",
          top: "0",
          right: 200,
          width: 110,
          height: 100,
          backgroundColor: colors.primary[400],
          display: "flex",
          // justifyContent: "space-between",
          flexDirection: "column",
          alignItems: "center",
          padding: 10,
          gap: 5,
        }}
      >
        <Typography
          variant="h5"
          fontWeight="bold"
          textAlign={"center"}
          sx={{ color: colors.grey[100] }}
        >
          Capacité Totale
        </Typography>
        <Typography
          variant="h3"
          fontWeight="bold"
          sx={{ color: colors.grey[100] }}
        >
          {currentParking?.size || 3000}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Rapport Quotidien"
          subtitle="Le rapport quotidien d'aujourd'hui met en avant les activités clés et les progrès réalisés."
        />

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={1}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                value={selectedDate}
                onChange={(value) => setSelectedDate(value)}
                label="Select The Date"
              />
            </DemoContainer>
          </LocalizationProvider>
          <DropDownMenu
            parkings={parkings}
            selectedItem={currentParking}
            handleChanged={handlePakringChanged}
          />
        </Box>

        {/* <PDFDownloadLink document={<PdfTemplate />} fileName="example.pdf">
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Download PDF"
          }
        </PDFDownloadLink> */}
      </Box>

      <Box
        display={"flex"}
        // flexDirection={"row-reverse"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        mb={2}
        gap={1}
      >
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          style={{
            backgroundColor: colors.blueAccent[700],
          }}
          endIcon={<IoCloudDownloadOutline />}
          onClick={() =>
            Rapport_Tickets_par_Types(
              ticketByTypes,
              currentParking,
              selectedDate,
              currentDayReport
            )
          }
        >
          Reparition Des Paiments Par Montants Paye
        </Button>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          style={{
            backgroundColor: colors.blueAccent[700],
          }}
          endIcon={<IoCloudDownloadOutline />}
          onClick={() =>
            StatisticesReport({
              selectedDate: selectedDate.format("YYYY-MM-D"),
              currentParkingName: currentParking.title,
              visitor_entry_count:
                parseInt(currentDayReport.in_count) -
                restDataOfTheTable.subscribers_entry_count,
              subscribers_entry_count:
                restDataOfTheTable.subscribers_entry_count,
              visitor_exit_count:
                parseInt(currentDayReport?.out_count || 0) +
                parseInt(currentDayReport?.mt_count || 0) +
                parseInt(currentDayReport?.pt_count || 0) -
                restDataOfTheTable.subscribers_exit_count,
              subscribers_exit_count: restDataOfTheTable.subscribers_exit_count,
              free_tickets: restDataOfTheTable?.free_tickets || 0,
              in_count: currentDayReport?.in_count || 0,
              out_count:
                parseInt(currentDayReport?.out_count) +
                  parseInt(currentDayReport?.mt_count) +
                  parseInt(currentDayReport?.pt_count) || 0,
              parking_size: currentParking?.size || 3000,

              avrage_occupation_rate:
                (restDataOfTheTable?.avrage_occupation_rate / 24).toFixed(2) ||
                0,
              avrage_time: restDataOfTheTable?.avrage_time || "0h : 0m : 0s",
              total_amount:
                parseInt(currentDayReport?.out_amount) +
                  parseInt(currentDayReport?.mt_amount) +
                  parseInt(currentDayReport?.pt_amount) || 0,
            })
          }
        >
          Statistiques Du Parking
        </Button>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          style={{
            backgroundColor: colors.blueAccent[700],
          }}
          endIcon={<IoCloudDownloadOutline />}
          onClick={() =>
            DailyReportPdf(
              currentParking,
              restDataOfTheTable,
              currentDayReport,
              selectedDate
            )
          }
        >
          Flix & chiffre d'affaire
        </Button>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="150px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            title={currentDayReport?.in_count}
            subtitle="Les Entrées D'aujourd'hui"
            // progress={2}
            // increase={100}
            icon={
              <FaCarSide
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            title={currentDayReport?.out_count}
            subtitle="Les Sorties d'aujourd'hui"
            // progress={2}
            // increase={100}
            // progress={calculate_percentage(
            //   statistics.out_count,
            //   statistics.last_out_count
            // )}
            // increase={calculate_percentage_compared_to_yesterday(
            //   statistics.out_count,
            //   statistics.last_out_count
            // )}
            icon={
              <IoCarSharp
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>

        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            // title={`${statistics.today_amount}DH`}
            title={currentDayReport.pt_amount}
            subtitle="Montant de Ticket Perdu"
            // progress={2}
            // increase={100}
            icon={
              // <FaMoneyBillTrendUp
              //   style={{ color: colors.greenAccent[600], fontSize: "26px" }}
              // />
              <TbTicketOff
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            // title={`${statistics.today_amount}DH`}
            title={currentDayReport?.mt_amount}
            subtitle="Montant de Ticket Illisible"
            // progress={2}
            // increase={100}
            icon={
              // <FaMoneyBillTrendUp
              //   style={{ color: colors.greenAccent[600], fontSize: "26px" }}
              // />
              <BsFillTicketDetailedFill
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>

        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            // title={`${statistics.today_amount}DH`}
            title={
              isNaN(parseInt(currentDayReport.out_amount))
                ? currentDayReport.out_amount
                : `${parseInt(currentDayReport?.pt_amount) +
                  parseInt(currentDayReport?.out_amount) +
                  parseInt(currentDayReport?.mt_amount)}DH`
            }
            subtitle="Montant total d'aujourd'hui"
            // progress={2}
            // increase={100}
            // progress={calculate_percentage(
            //   statistics.today_amount,
            //   statistics.yester_day_amount
            // )}
            // increase={calculate_percentage_compared_to_yesterday(
            //   statistics.today_amount,
            //   statistics.yester_day_amount
            // )}
            icon={
              <FaMoneyBillTrendUp
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            // title={`${statistics.today_amount}DH`}
            title={restDataOfTheTable?.prev_day_amount}
            subtitle="Montant total d'hier"
            // progress={2}
            // increase={100}
            // progress={calculate_percentage(
            //   statistics.today_amount,
            //   statistics.yester_day_amount
            // )}
            // increase={calculate_percentage_compared_to_yesterday(
            //   statistics.today_amount,
            //   statistics.yester_day_amount
            // )}
            icon={
              <FaMoneyBill1
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>
      </Box>

      {/* ROW2 */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="250px"
        mt="20px"
        gap={"20px"}
      >
        <RevenueChart RevenueData={revenueOfSpecificDay} />
        <DailyTable
          currentDayReport={currentDayReport}
          currentParking={currentParking}
          restDataOfTheTable={restDataOfTheTable}
        />
      </Box>

      {/* DataGrid 3 */}
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={dailyReport}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => parseInt(Math.random() * 10000)}
        />
      </Box>
    </Box>
  );
};

export default DailyReport;
