import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import DropDownMenu from "../../components/DropDownMenu";
import { useEffect, useState } from "react";
import {
  dateFormater,
  fetchingData,
  fetchingDataForChart,
} from "../../Utils/helpers";
import PdfTemplate from "../../components/PdfTemplate";
import LineChart from "../../components/LineChart";
import CustomTabs from "../../components/Tabs";
import RegisteredCars from "../dashboard/RegisteredCars";
// import  '@react-pdf/renderer'
// ===================================================
import { FaCarSide } from "react-icons/fa";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FaMoneyBill1 } from "react-icons/fa6";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { mockDataContacts } from "../../data/mockData";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import SubscribersBarChart from "./SubscribersBarChart";
import { mockBarDataPricing } from "../../data/mockData";
import { ImportExportOutlined } from "@mui/icons-material";
import SubTable from "./SubTable";
import SubscribersTypesTable from "./SubscribersTypesTable";
import dayjs from "dayjs";
import {
  DailyReportPdf,
  Rapport_Tickets_par_Types,
  StatisticesReport,
} from "../../components/Pdf Templates/statisticesRep";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import StatBoxExtend from "../../components/StatBoxExtend";
import { initializeConnect } from "react-redux/es/components/connect";
//====================================================================
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import { IoCarSharp } from "react-icons/io5";
import { GrUpdate } from "react-icons/gr";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const SubscribersLiveData = ({ socket }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [startDate, setStartDate] = useState(dayjs().startOf("month"));
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const [basicMonthlyStatistices, setBasicMonthlyStatistices] = useState([]);
  const [subscribersChartData, setSubscribersChartData] = useState([
    {
      month: "0-15min",
      period: 20,
      periodColor: "hsl(307, 70%, 50%)",
    },

    {
      month: "16-30min",
      period: 67,
      periodColor: "hsl(307, 70%, 50%)",
    },

    {
      month: "31-1h30min",
      period: 45,
      periodColor: "hsl(307, 70%, 50%)",
    },

    {
      month: "1:30h-3hH",
      period: 85,
      periodColor: "hsl(307, 70%, 50%)",
    },

    {
      month: "3h-5h",
      period: 34,
      periodColor: "hsl(307, 70%, 50%)",
    },

    {
      month: "5h-8h",
      period: 13,
      periodColor: "hsl(307, 70%, 50%)",
    },

    {
      month: "8h-12h",
      period: 75,
      periodColor: "hsl(307, 70%, 50%)",
    },

    {
      month: "12h-16h",
      period: 74,
      periodColor: "hsl(307, 70%, 50%)",
    },

    {
      month: "16h-20h",
      period: 22,
      periodColor: "hsl(307, 70%, 50%)",
    },
    {
      month: "20h-24h",
      period: 87,
      periodColor: "hsl(307, 70%, 50%)",
    },
  ]);

  const [subscribersEntryExits, setSubscribersEntryExits] = useState({
    "24h": {
      entry: 0,
      exit: 0,
    },
    "8h": {
      entry: 0,
      exit: 0,
    },
    "10h": {
      entry: 0,
      exit: 0,
    },
    entryCount: 0,
    exitCount: 0,
  });

  const [newAndRenwalSubscribers, setNewAndRenwalSubscribers] = useState({
    new: 0,
    renew: 0,
    expired: 0,
    amount: 0,
  });

  const [subscribersCategoriesCountes, setSubscribersCategoriesCountes] =
    useState({
      ar_count_8: 0,
      ar_count_10: 0,
      ar_count_24: 0,
      co_count_8: 0,
      co_count_10: 0,
      co_count_24: 0,
      dv_count_8: 0,
      dv_count_10: 0,
      dv_count_24: 0,
      ed_count_8: 0,
      ed_count_10: 0,
      ed_count_24: 0,
      no_re_count_8: 0,
      no_re_count_10: 0,
      no_re_count_24: 0,
      pro_count_8: 0,
      pro_count_10: 0,
      pro_count_24: 0,
      re_count_8: 0,
      re_count_10: 0,
      re_count_24: 0,
      st_count_8: 0,
      st_count_10: 0,
      st_count_24: 0,
      tr_count_8: 0,
      tr_count_10: 0,
      tr_count_24: 0,
    });
  const [
    revenuForTheDaysOFTheCurrentMonth,
    setRevenuForTheDaysOFTheCurrentMonth,
  ] = useState([
    {
      id: "Revenu Of The Month",
      color: colors.blueAccent[200],
      data: [],
    },
  ]);
  const [parkings, setParkings] = useState([]);
  const tabs = [
    {
      id: 0,
      title: "This Month",
    },
    {
      id: 1,
      title: "Pervious Month",
    },
  ];

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "registrarId", headerName: "Registrar ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
    },
    {
      field: "zipCode",
      headerName: "Zip Code",
      flex: 1,
    },
  ];

  const handleMonthChange = (e) => {
    let month_id = e.target.id;
    fetchingDataForChart(
      `report/revenuForTheDaysOFTheCurrentMonth?prev_month=${month_id}`,
      setRevenuForTheDaysOFTheCurrentMonth
    );
  };

  const handlePakringChanged = (Parking, setAnchorEl) => {
    setCurrentParking(Parking);
    setAnchorEl(null);
  };
  const [currentParking, setCurrentParking] = useState({
    id: 1,
    title: "Bab Jdid P1-P2",
  });

  useEffect(() => {
    if (startDate >= endDate) {
      setStartDate(dayjs(endDate).subtract(1, "day"));
    }

    if (endDate > startDate) {
      setSubscribersEntryExits({
        "24h": {
          entry: (
            <CircularProgress
              size={30}
              style={{ color: colors.blueAccent[100] }}
            />
          ),
          exit: (
            <CircularProgress
              size={30}
              style={{ color: colors.blueAccent[100] }}
            />
          ),
        },
        "8h": {
          entry: (
            <CircularProgress
              size={30}
              style={{ color: colors.blueAccent[100] }}
            />
          ),
          exit: (
            <CircularProgress
              size={30}
              style={{ color: colors.blueAccent[100] }}
            />
          ),
        },
        "10h": {
          entry: (
            <CircularProgress
              size={30}
              style={{ color: colors.blueAccent[100] }}
            />
          ),
          exit: (
            <CircularProgress
              size={30}
              style={{ color: colors.blueAccent[100] }}
            />
          ),
        },
        entryCount: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
        exitCount: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
      });

      setNewAndRenwalSubscribers({
        new: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
        renew: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
        amount: (
          <CircularProgress
            size={30}
            style={{ color: colors.blueAccent[100] }}
          />
        ),
      });

      if (socket) {
        socket.emit("Subscribers/start", {
          parking_id: currentParking.id,
          start_date: startDate.format("YYYY-MM-DD"),
          end_date: endDate.format("YYYY-MM-DD"),
        });

        socket.on("Subscribers/subscribersOccupationRanges", ({ data }) => {
          data = data?.data[0];
          setSubscribersChartData([
            {
              month: "0-15min",
              period: data["between_0_and_15"],
              periodColor: "hsl(307, 70%, 50%)",
            },

            {
              month: "16-30min",
              period: data["between_16_and_30"],
              periodColor: "hsl(307, 70%, 50%)",
            },

            {
              month: "31-1h30min",
              period: data["between_31min_and_1:30h"],
              periodColor: "hsl(307, 70%, 50%)",
            },

            {
              month: "1:30h-3hH",
              period: data["between_1:31h_and_3h"],
              periodColor: "hsl(307, 70%, 50%)",
            },

            {
              month: "3h-5h",
              period: data["between_3h_and_5h"],
              periodColor: "hsl(307, 70%, 50%)",
            },

            {
              month: "5h-8h",
              period: data["between_5h_and_8h"],
              periodColor: "hsl(307, 70%, 50%)",
            },

            {
              month: "8h-12h",
              period: data["between_8h_and_12h"],
              periodColor: "hsl(307, 70%, 50%)",
            },

            {
              month: "12h-16h",
              period: data["between_12h_and_16h"],
              periodColor: "hsl(307, 70%, 50%)",
            },

            {
              month: "16h-20h",
              period: data["between_16h_and_20h"],
              periodColor: "hsl(307, 70%, 50%)",
            },
            {
              month: "20h-24h",
              period: data["between_16h_and_24h"],
              periodColor: "hsl(307, 70%, 50%)",
            },
          ]);
        });

        socket.on("Subscribers/subscribersCategoriesCountes", ({ data }) => {
          data = data?.data[0];
          // console.log("====================================");
          // console.log("Subscribers/subscribersCategoriesCountes", data);
          // console.log("====================================");

          setSubscribersCategoriesCountes({
            ar_count_8: data["ar_count_8"] || 0,
            ar_count_10: data["ar_count_10"] || 0,
            ar_count_24: data["ar_count_24"] || 0,
            co_count_8: data["co_count_8"] || 0,
            co_count_10: data["co_count_10"] || 0,
            co_count_24: data["co_count_24"] || 0,
            dv_count_8: data["dv_count_8"] || 0,
            dv_count_10: data["dv_count_10"] || 0,
            dv_count_24: data["dv_count_24"] || 0,
            ed_count_8: data["ed_count_8"] || 0,
            ed_count_10: data["ed_count_10"] || 0,
            ed_count_24: data["ed_count_24"] || 0,
            no_re_count_8: data["no_re_count_8"] || 0,
            no_re_count_10: data["no_re_count_10"] || 0,
            no_re_count_24: data["no_re_count_24"] || 0,
            pro_count_8: data["pro_count_8"] || 0,
            pro_count_10: data["pro_count_10"] || 0,
            pro_count_24: data["pro_count_24"] || 0,
            re_count_8: data["re_count_8"] || 0,
            re_count_10: data["re_count_10"] || 0,
            re_count_24: data["re_count_24"] || 0,
            st_count_8: data["st_count_8"] || 0,
            st_count_10: data["st_count_10"] || 0,
            st_count_24: data["st_count_24"] || 0,
            tr_count_8: data["tr_count_8"] || 0,
            tr_count_10: data["tr_count_10"] || 0,
            tr_count_24: data["tr_count_24"] || 0,
          });
        });

        socket.on("Subscribers/subscribersEntryExitCount", ({ data }) => {
          data = data?.data;
          // console.log("====================================");
          // console.log("Subscribers/subscribersEntryExitCount", data);
          // console.log("====================================");
          let entryExitInitialData = {
            "24h": {
              entry: 0,
              exit: 0,
            },
            "8h": {
              entry: 0,
              exit: 0,
            },
            "10h": {
              entry: 0,
              exit: 0,
            },
            entryCount: 0,
            exitCount: 0,
          };
          setSubscribersEntryExits(entryExitInitialData);
          data?.map((item) => {
            if (item.is_from == 1) {
              entryExitInitialData["24h"].entry = parseInt(item.entry);
              entryExitInitialData["24h"].exit = parseInt(item.exit);
            } else if (item.is_from == 2) {
              entryExitInitialData["8h"].entry = parseInt(item.entry);
              entryExitInitialData["8h"].exit = parseInt(item.exit);
            } else if (item.is_from == 3) {
              entryExitInitialData["10h"].entry = parseInt(item.entry);
              entryExitInitialData["10h"].exit = parseInt(item.exit);
            } else {
              entryExitInitialData["24h"].entry = parseInt(item.entry);
              entryExitInitialData["24h"].exit = parseInt(item.exit);
            }
            entryExitInitialData.entryCount += parseInt(item.entry);
            entryExitInitialData.exitCount += parseInt(item.exit);
          });

          setSubscribersEntryExits(entryExitInitialData);
        });

        socket.on(
          "Subscribers/newAndRenewSubscribersWithAmount",
          ({ data }) => {
            data = data?.data;
            let newAndRenewInitialData = {
              new: 0,
              renew: 0,
              amount: 0,
            };
            data?.map((item) => {
              if (item.number_of_time == "1") {
                newAndRenewInitialData.new = parseInt(item.count);
              } else {
                newAndRenewInitialData.renew += parseInt(item.count);
              }
              newAndRenewInitialData.amount += parseInt(item?.sum || 0);
            });
            newAndRenewInitialData.amount = `${newAndRenewInitialData.amount}DH`;
            newAndRenewInitialData.expired = newAndRenwalSubscribers.expired;
            setNewAndRenwalSubscribers(newAndRenewInitialData);
            // console.log("====================================");
            // console.log("Subscribers/newAndRenewSubscribersWithAmount", data);
            // console.log("====================================");
          }
        );

        socket.on("Subscribers/expiredSubscribers", ({ data }) => {
          data = data?.data;
          console.log("====================================");
          console.log("Subscribers/expiredSubscribers", data);
          console.log("====================================");
          setNewAndRenwalSubscribers((prev) => ({
            ...prev,
            expired: data?.[0]?.count || 0, // Defaults to 0 if data is undefined
          }));
        });
      }
    }
    // fetchingData("report/BasicMonthlyStatistices", setBasicMonthlyStatistices);
    // // fetchingDataForChart(
    // //   "report/revenuForTheDaysOFTheCurrentMonth",
    // //   setRevenuForTheDaysOFTheCurrentMonth
    // // );
    // fetchingDataForChart(
    //   "report/hourlyOccupanyRate?date=2024-08-01",
    //   setRevenuForTheDaysOFTheCurrentMonth
    // );
    // fetchingData("report/daily?date=2024-08-01", setMonth);
    // getAll Parkings =====================
    fetchingData(`global/getAllParkings`, setParkings);
  }, [startDate, endDate, currentParking]);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Subscription Live Data"
          subtitle="Subscription Live Data, highlights key activities and progress"
        />

        <Box
          display={"flex"}
          flex={2}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs} key={1}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                value={startDate}
                onChange={(value) => setStartDate(value)}
                label="Start Date"
              />
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} key={2}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                value={endDate}
                onChange={(value) => setEndDate(value)}
                label="End Date"
              />
            </DemoContainer>
          </LocalizationProvider>
          <DropDownMenu
            parkings={parkings}
            selectedItem={currentParking}
            handleChanged={handlePakringChanged}
          />
        </Box>

        {/* <PDFDownloadLink document={<PdfTemplate />} fileName="example.pdf">
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Download PDF"
          }
        </PDFDownloadLink> */}
      </Box>

      {/* <Box
        display={"flex"}
        // flexDirection={"row-reverse"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        mb={2}
        gap={1}
      >
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          style={{
            backgroundColor: colors.blueAccent[700],
          }}
          endIcon={<IoCloudDownloadOutline />}
          onClick={() => Rapport_Tickets_par_Types()}
        >
          Reparition Des Paiments Par Montants Paye
        </Button>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          style={{
            backgroundColor: colors.blueAccent[700],
          }}
          endIcon={<IoCloudDownloadOutline />}
          onClick={() => StatisticesReport({})}
        >
          Statistiques Du Parking
        </Button>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          style={{
            backgroundColor: colors.blueAccent[700],
          }}
          endIcon={<IoCloudDownloadOutline />}
          onClick={() => DailyReportPdf()}
        >
          Flix & chiffre d'affaire
        </Button>
      </Box> */}

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            title={subscribersEntryExits.entryCount}
            subtitle="Total des entrées de cette période"
            icon={
              <FaCarSide
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            title={subscribersEntryExits.exitCount}
            subtitle="Total Des Dorties De Cette Période"
            icon={
              <IoCarSharp
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            title={newAndRenwalSubscribers.new}
            subtitle="Nouvelles Abonnes"
            icon={
              <DataSaverOnIcon
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            title={newAndRenwalSubscribers.renew}
            subtitle="Nombre Renouvelé"
            icon={
              <GrUpdate
                style={{ color: colors.greenAccent[600], fontSize: "25px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            title={newAndRenwalSubscribers.expired}
            subtitle="Nombre Expirés"
            icon={
              <DeleteForeverIcon
                style={{ color: colors.greenAccent[600], fontSize: "35px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            title={newAndRenwalSubscribers.amount}
            subtitle="Le montant total de la période en cours"
            icon={
              <FaMoneyBillTrendUp
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        // backgroundColor={colors.primary[100]}
        gap={2}
        mt={4}
      >
        <SubscribersBarChart subscribersChartData={subscribersChartData} />
        <SubTable subscribersEntryExits={subscribersEntryExits} />
      </Box>
      <Box sx={{ padding: 2 }}>
        <SubscribersTypesTable
          subscribersCategoriesCountes={subscribersCategoriesCountes}
        />
      </Box>
      {/* <SubTable /> */}
      {/* <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={mockDataContacts}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box> */}
    </Box>
  );
};

export default SubscribersLiveData;
