import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const FormDialog = ({ open, handleClickOpen, handleClose }) => {
  const [inputData, setInputData] = useState({ name: "", email: "" });
  const [reportsTypes, setReportsTypes] = useState([
    "C_A_Quotidien",
    "Rapport_Graphique",
    "Rapport_Tickets_par_Types",
    "Report_Nouveaux_Abonnee",
    "Report_Statistiques",
  ]);
  const handleInputChange = (e) => {
    setInputData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    // console.log(e.target.name);
    console.log(inputData);
  };
  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button>
      <Dialog
        sx={{
          "& form": {
            backgroundColor: "white",
            color: "black",
          },

          "& input": {
            borderBottom: "1px solid black",
            color: "black",
          },
          "& form label": {
            color: "black !important",
          },
        }}
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle>Add Mail To Receive Mail</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              color: "black !important",
            }}
          >
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            sx={{
              margin: "0 0 10px 0",
            }}
            // value={name}
            onChange={handleInputChange}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="email"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            sx={{
              margin: "0 0 20px 0",
            }}
            onChange={handleInputChange}
          />

          <FormGroup
            sx={{
              "& span": {
                color: "black",
              },
            }}
          >
            <Box display="flex" flexWrap="wrap">
              {reportsTypes.map((item, i) => (
                <FormControlLabel
                  key={i}
                  control={<Checkbox defaultChecked={i == 0} />}
                  label={item}
                />
              ))}
            </Box>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Subscribe</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default FormDialog;
