import React from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Box, Typography, useTheme } from "@mui/material";
import { CGP_IMAGE } from "../../Constant";

// icons ----------------------------------------------------------
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { tokens } from "../../theme";
import { ImOffice } from "react-icons/im";
import dayjs from "dayjs";

// const StatisticesReport = ({ data }) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const generatePDF = () => {
//     const doc = new jsPDF();
//     const pageWidth = doc.internal.pageSize.getWidth();
//     const pageHeight = doc.internal.pageSize.getHeight();
//     // Title
//     doc.setFont("helvetica", "bold");
//     doc.setFontSize(14);
//     doc.text("Statistiques d'Abonnements Bab Jdid P1-P2", pageWidth / 2, 20, {
//       align: "center",
//     });

//     const base64Image = CGP_IMAGE;
//     doc.addImage(base64Image, "PNG", pageWidth - 35, 12, 30, 13);
//     // Subtitle
//     doc.setFont("helvetica", "normal");
//     doc.setFontSize(10);
//     doc.text(
//       "Ce rapport présente le chiffres d'affaire des visiteurs ainsi que",
//       pageWidth / 2 - 30,
//       40
//     );
//     doc.text(
//       "le détail des entrées sorties dans un parking pendant une période donnée.",
//       pageWidth / 2 - 30,
//       44
//     );
//     const bgColor = { r: 151, g: 200, b: 237 };
//     doc.setFillColor(bgColor.r, bgColor.g, bgColor.b);
//     doc.rect(0, 50, pageWidth, 10, "F");
//     // Adding a line
//     // doc.setLineWidth(0.5);
//     // doc.line(10, 40, 200, 40);

//     // Add some body text
//     doc.setFont("helvetica", "bold");
//     doc.setFontSize(16);
//     doc.text("CA visiteurs du parkings Bab Jdid P1-P2", 10, 57);

//     // doc.(16);
//     doc.setFont("helvetica", "normal");
//     doc.text("Chiffre d'affaires du : 2024-09-07", 10, 70);
//     doc.text("Parking Bab Jdid P1-P2", 10, 78);

//     // Adding a table
//     const columns = [
//       "Parking",
//       "",
//       "",
//       "N.E Jour",
//       "N.E Nuit",
//       "N.S Jour",
//       "N.S Nuit",
//       "N.T Illisible",
//       "Tk.Perdus",
//     ];
//     const rows = [
//       [{ content: "Bab Jdid P1-P2", colSpan: 3 }, 401, 200, 302, 112, 42, 3],
//       [
//         { content: "Nomber Total", colSpan: 3 },
//         { content: 601, colSpan: 2 },
//         { content: 414, colSpan: 2 },
//         "-",
//         "4 Days",
//       ],
//       [
//         { content: "Total", colSpan: 3 },
//         { content: "-", colSpan: 2 },
//         { content: "4525DH", colSpan: 2 },
//         "40DH",
//         "160DH",
//       ],
//       [
//         { content: "Chiffre d'affaires global", colSpan: 5 },
//         { content: "4521DH", colSpan: 5, fillColor: [0, 102, 204] },
//       ],
//       // [{ content: "Nombre Total", colSpan: 2}, {content: 601, colSpan: 2}, 302, 112, 42, 3]
//       // [1, "John Doe", "2024-09-07", "$200", "754"],
//       // [1, "John Doe", "2024-09-07", "$200", "754"],
//       // [1, "John Doe", "2024-09-07", "$200", "754"],
//       // [1, "John Doe", "2024-09-07", "$200", "754"],
//       // [1, "John Doe", "2024-09-07", "$200", "754"],
//     ];
//     console.log('====================================');
//     console.log(data.row);
//     console.log('====================================');
//     // Use autoTable to generate the table
//     doc.autoTable({
//       startY: 85, // Y position where the table starts
//       head: [columns],
//       body: rows,
//       theme: "grid",
//       headStyles: {
//         fillColor: [0, 102, 204], // Blue header background
//         textColor: [255, 255, 255], // White header text
//         fontSize: 10, // Font size of the header
//       }, // Optional: 'striped', 'plain', or 'grid'
//       styles: { halign: "center" }, // Optional: customize colors
//     });

//     // Save the PDF
//     doc.setFont("helvetica");
//     doc.setFontSize(12);
//     doc.text("Compagnie General Des Parking", pageWidth / 2, pageHeight - 10, {
//       align: "center",
//     });
//     doc.save("Parking_Statistics.pdf");
//   };

//   return (
//     // <div>
//     //   <button onClick={generatePDF}>Generate PDF</button>
//     // </div>
//     <div onClick={generatePDF}>
//       <Box
//         m="0 auto"
//         p="5px"
//         display="flex"
//         justifyContent="center"
//         backgroundColor={colors.greenAccent[600]}
//         borderRadius="4px"
//         sx={{
//           // backgroundColor: 'primary.main',
//           "&:hover": {
//             cursor: "pointer",
//           },
//         }}
//       >
//         <PictureAsPdfIcon color="white" />
//         <Typography color="white" sx={{ ml: "5px" }}>
//           Print
//         </Typography>

//         {/* <PDFDownloadLink document={<PdfTemplate />} fileName="example.pdf">
//           {({ blob, url, loading, error }) =>
//               loading ? 'Loading document...' : 'Download PDF'
//           }
//       </PDFDownloadLink> */}
//       </Box>
//     </div>
//   );
// };

// export default StatisticesReport;

export const StatisticesReport = (data) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Title
  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.text(
    `Statistiques Du Parking: ${data?.currentParkingName?.trim() || ""}`,
    pageWidth / 2,
    20,
    {
      align: "center",
    }
  );

  const base64Image = CGP_IMAGE;
  doc.addImage(base64Image, "PNG", pageWidth - 35, 12, 30, 13);
  // Subtitle
  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);
  doc.text(
    "Ce rapport inclut des informations complètes sur la capacité du parking, le nombre d'entrées et de",
    30,
    40
  );
  doc.text(
    "sorties, les taux d'occupation, et d'autres indicateurs clés de performance pour le parking",
    40,
    44
  );
  doc.text(`* ${data?.currentParkingName?.trim()} *`, pageWidth / 2 - 15, 50);
  const bgColor = { r: 151, g: 200, b: 237 };
  doc.setFillColor(bgColor.r, bgColor.g, bgColor.b);
  doc.rect(0, 56, pageWidth, 10, "F");
  // Adding a line
  // doc.setLineWidth(0.5);
  // doc.line(10, 40, 200, 40);

  // Add some body text
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.text(
    `CA visiteurs du parkings ${data?.currentParkingName || ""}`,
    10,
    63
  );

  // doc.(16);
  doc.setFont("helvetica", "normal");
  doc.text(
    `Statistiques du ${data?.currentParkingName?.trim() || ""} le ${
      data?.selectedDate || ""
    }`,
    10,
    76
  );
  //   doc.text("Parking Bab Jdid P1-P2", 10, 84);

  // Adding a table
  const columns = [
    { content: "CDG - CGP Ville De Fes", colSpan: 1 },
    { content: data?.currentParkingName?.trim(), align: "center" },
  ];

  const rows = [
    ["Capacité du parking (CP)", data?.parking_size],
    ["Nbre des Entrées (NE)", data?.in_count],
    ["dont abonnées", data?.subscribers_entry_count],
    ["dont visiteurs", data?.visitor_entry_count],
    ["Nbre des Sorties (NS)", data?.out_count],
    ["dont abonnées", data?.subscribers_exit_count],
    ["dont visiteurs", data?.visitor_exit_count],
    ["Tickets < 15min", data?.free_tickets],
    ["Chiffre d'Affaire (DH)", data?.total_amount],
    [
      "Ticket Moyen (DH)",
      (data?.total_amount / data?.out_count || 0).toFixed(2),
    ],
    ["Ratio de Rotation (NE) / (CP)", 0],
    ["Taux d'occupation moyen du parking", `${data?.avrage_occupation_rate} %`],
    ["Durée moyenne de stationnement", data?.avrage_time],

    // [
    //   { content: "Nomber Total", colSpan: 3 },
    //   { content: 601, colSpan: 2 },
    //   { content: 414, colSpan: 2 },
    //   "-",
    //   "4 Days",
    // ],
    // [
    //   { content: "Total", colSpan: 3 },
    //   { content: "-", colSpan: 2 },
    //   { content: "4525DH", colSpan: 2 },
    //   "40DH",
    //   "160DH",
    // ],
    // [
    //   { content: "Chiffre d'affaires global", colSpan: 5 },
    //   { content: "4521DH", colSpan: 5, fillColor: [0, 102, 204] },
    // ],
    // [{ content: "Nombre Total", colSpan: 2}, {content: 601, colSpan: 2}, 302, 112, 42, 3]
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
  ];
  //   console.log("====================================");
  //   //   console.log(data.row);
  //   console.log("====================================");
  // Use autoTable to generate the table
  doc.autoTable({
    startY: 84, // Y position where the table starts
    head: [columns],
    body: rows,
    theme: "grid",
    headStyles: {
      fillColor: [0, 102, 204], // Blue header background
      textColor: [255, 255, 255], // White header text
      fontSize: 10, // Font size of the header
    }, // Optional: 'striped', 'plain', or 'grid'
    styles: { halign: "center" }, // Optional: customize colors
  });

  // Save the PDF
  doc.setFont("helvetica");
  doc.setFontSize(12);
  doc.text("Compagnie General Des Parking", pageWidth / 2, pageHeight - 10, {
    align: "center",
  });
  doc.save("Report_Statistiques.pdf");
};
export const StatisticesReport_Periodic = (data) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Title
  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.text(
    `Statistiques Du Parking: ${data?.currentParkingName?.trim() || ""}`,
    pageWidth / 2,
    20,
    {
      align: "center",
    }
  );

  const base64Image = CGP_IMAGE;
  doc.addImage(base64Image, "PNG", pageWidth - 35, 12, 30, 13);
  // Subtitle
  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);
  doc.text(
    "Ce rapport inclut des informations complètes sur la capacité du parking, le nombre d'entrées et de",
    30,
    40
  );
  doc.text(
    "sorties, les taux d'occupation, et d'autres indicateurs clés de performance pour le parking",
    40,
    44
  );
  doc.text(`* ${data?.currentParkingName?.trim()} *`, pageWidth / 2 - 15, 50);
  const bgColor = { r: 151, g: 200, b: 237 };
  doc.setFillColor(bgColor.r, bgColor.g, bgColor.b);
  doc.rect(0, 56, pageWidth, 10, "F");
  // Adding a line
  // doc.setLineWidth(0.5);
  // doc.line(10, 40, 200, 40);

  // Add some body text
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.text(
    `CA visiteurs du parkings ${data?.currentParkingName || ""}`,
    10,
    63
  );

  // doc.(16);
  doc.setFont("helvetica", "normal");
  doc.text(
    `Statistiques du ${data?.currentParkingName?.trim() || ""} enter ${
      data?.startDate || ""
    } et ${data?.endDate || ""}`,
    10,
    76
  );
  //   doc.text("Parking Bab Jdid P1-P2", 10, 84);

  // Adding a table
  const columns = [
    { content: "CDG - CGP Ville De Fes", colSpan: 1 },
    { content: data?.currentParkingName?.trim(), align: "center" },
  ];

  const rows = [
    ["Capacité du parking (CP)", data?.parking_size],
    ["Nbre des Entrées (NE)", data?.in_count],
    ["dont abonnées", data?.subscribers_entry_count],
    ["dont visiteurs", data?.visitor_entry_count],
    ["Nbre des Sorties (NS)", data?.out_count],
    ["dont abonnées", data?.subscribers_exit_count],
    ["dont visiteurs", data?.visitor_exit_count],
    ["Tickets < 15min", data?.free_tickets],
    ["Chiffre d'Affaire (DH)", data?.total_amount],
    [
      "Ticket Moyen (DH)",
      (data?.total_amount / data?.out_count || 0).toFixed(2),
    ],
    ["Ratio de Rotation (NE) / (CP)", 0],
    ["Taux d'occupation moyen du parking", `${data?.avrage_occupation_rate} %`],
    ["Durée moyenne de stationnement", data?.avrage_time],

    // [
    //   { content: "Nomber Total", colSpan: 3 },
    //   { content: 601, colSpan: 2 },
    //   { content: 414, colSpan: 2 },
    //   "-",
    //   "4 Days",
    // ],
    // [
    //   { content: "Total", colSpan: 3 },
    //   { content: "-", colSpan: 2 },
    //   { content: "4525DH", colSpan: 2 },
    //   "40DH",
    //   "160DH",
    // ],
    // [
    //   { content: "Chiffre d'affaires global", colSpan: 5 },
    //   { content: "4521DH", colSpan: 5, fillColor: [0, 102, 204] },
    // ],
    // [{ content: "Nombre Total", colSpan: 2}, {content: 601, colSpan: 2}, 302, 112, 42, 3]
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
  ];
  //   console.log("====================================");
  //   //   console.log(data.row);
  //   console.log("====================================");
  // Use autoTable to generate the table
  doc.autoTable({
    startY: 84, // Y position where the table starts
    head: [columns],
    body: rows,
    theme: "grid",
    headStyles: {
      fillColor: [0, 102, 204], // Blue header background
      textColor: [255, 255, 255], // White header text
      fontSize: 10, // Font size of the header
    }, // Optional: 'striped', 'plain', or 'grid'
    styles: { halign: "center" }, // Optional: customize colors
  });

  // Save the PDF
  doc.setFont("helvetica");
  doc.setFontSize(12);
  doc.text("Compagnie General Des Parking", pageWidth / 2, pageHeight - 10, {
    align: "center",
  });
  doc.save("Report_Statistiques.pdf");
};

export const Rapport_Tickets_par_Types = (
  ticketByTypes,
  currentParking,
  selectedDate,
  currentDayReport
) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  // Title
  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);

  let [free_tickets_count, free_tickets_amount] = ticketByTypes.free_cars
    .split("|")
    .map(Number);

  let [between_2_and_3_count, between_2_and_3_amount] =
    ticketByTypes.count_between_2_and_3.split("|").map(Number);

  let [between_4_and_5_count, between_4_and_5_amount] =
    ticketByTypes.count_between_4_and_5.split("|").map(Number);

  let [between_6_and_60_count, between_6_and_60_amount] =
    ticketByTypes.count_between_6_and_60.split("|").map(Number);

  let [between_61_and_120_count, between_61_and_120_amount] =
    ticketByTypes.count_between_61_and_120.split("|").map(Number);

  let [between_121_and_180_count, between_121_and_180_amount] =
    ticketByTypes.count_between_121_and_180.split("|").map(Number);

  let [more_than_180_count, more_than_180_amount] = ticketByTypes.more_than_180
    .split("|")
    .map(Number);

  let total_count =
    free_tickets_count +
    between_2_and_3_count +
    between_4_and_5_count +
    between_6_and_60_count +
    between_61_and_120_count +
    between_121_and_180_count +
    more_than_180_count +
    currentDayReport.pt_count;
  let total_amount =
    free_tickets_amount +
    between_2_and_3_amount +
    between_4_and_5_amount +
    between_6_and_60_amount +
    between_61_and_120_amount +
    between_121_and_180_amount +
    more_than_180_amount +
    parseInt(currentDayReport?.pt_amount || 0);

  doc.text(
    `Statistiques Du Parking: ${currentParking.title.trim()}`,
    pageWidth / 2,
    20,
    {
      align: "center",
    }
  );

  const base64Image = CGP_IMAGE;
  doc.addImage(base64Image, "PNG", pageWidth - 35, 12, 30, 13);
  // Subtitle
  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);
  doc.text(
    "Ce rapport présente les différents types de tickets et les montants collectés dans le parking",
    pageWidth / 2,
    40,
    {
      align: "center",
    }
  );
  //   doc.text(
  //     "sorties, les taux d'occupation, et d'autres indicateurs clés de performance pour le parking",
  //     40,
  //     44
  //   );
  doc.text(
    `${currentParking.title.trim()} le ${selectedDate.format("YYYY-MM-D")}`,
    pageWidth / 2 - 15,
    46
  );

  // doc.(16);
  doc.setFont("helvetica", "normal");
  //   doc.text("Parking Bab Jdid P1-P2", 10, 84);

  // Adding a table
  const columns = [
    "Type de tickets",
    "Nombre de tickets",
    "Montant total (DH)",
  ];
  const rows = [
    ["Ticket gratuit", free_tickets_count, `${free_tickets_amount} DH`],
    ["Ticket perdus", currentDayReport.pt_count, currentDayReport.pt_amount],
    ["Ticket 1 heure", between_2_and_3_count, `${between_2_and_3_amount} DH`],
    ["Ticket 2 heures", between_4_and_5_count, `${between_4_and_5_amount} DH`],
    [
      "Ticket <= 60 DH & durée > 2 heures",
      between_6_and_60_count,
      `${between_6_and_60_amount} DH`,
    ],
    [
      "60 DH < Ticket <= 120 DH",
      between_61_and_120_count,
      `${between_61_and_120_amount} DH`,
    ],
    [
      "120 DH < Ticket <= 180 DH",
      between_121_and_180_count,
      `${between_121_and_180_amount} DH`,
    ],
    ["Ticket > 180 DH", more_than_180_count, `${more_than_180_amount} DH`],
    ["Total", total_count, `${total_amount} DH`],
    [
      "Ratio (DH/Ticket",
      { content: (total_amount / (total_count || 1)).toFixed(2), colSpan: 2 },
    ],
    // [
    //   { content: "Nomber Total", colSpan: 3 },
    //   { content: 601, colSpan: 2 },
    //   { content: 414, colSpan: 2 },
    //   "-",
    //   "4 Days",
    // ],
    // [
    //   { content: "Total", colSpan: 3 },
    //   { content: "-", colSpan: 2 },
    //   { content: "4525DH", colSpan: 2 },
    //   "40DH",
    //   "160DH",
    // ],
    // [
    //   { content: "Chiffre d'affaires global", colSpan: 5 },
    //   { content: "4521DH", colSpan: 5, fillColor: [0, 102, 204] },
    // ],
    // [{ content: "Nombre Total", colSpan: 2}, {content: 601, colSpan: 2}, 302, 112, 42, 3]
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
  ];
  console.log("====================================");
  //   console.log(data.row);
  console.log("====================================");
  // Use autoTable to generate the table
  doc.autoTable({
    startY: 60, // Y position where the table starts
    head: [columns],
    body: rows,
    theme: "grid",
    headStyles: {
      fillColor: [0, 102, 204], // Blue header background
      textColor: [255, 255, 255], // White header text
      fontSize: 10, // Font size of the header
    }, // Optional: 'striped', 'plain', or 'grid'
    styles: { halign: "center" }, // Optional: customize colors
  });

  // Save the PDF
  doc.setFont("helvetica");
  doc.setFontSize(12);
  doc.text("Compagnie General Des Parking", pageWidth / 2, pageHeight - 10, {
    align: "center",
  });
  doc.save("Rapport_Tickets_par_Types.pdf");
};
export const Rapport_Tickets_par_Types_periodic = (
  ticketByTypes,
  currentParking,
  currentDayReport,
  startDate,
  endDate
) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  // Title
  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);

  let [free_tickets_count, free_tickets_amount] = ticketByTypes.free_cars
    .split("|")
    .map(Number);

  let [between_2_and_3_count, between_2_and_3_amount] =
    ticketByTypes.count_between_2_and_3.split("|").map(Number);

  let [between_4_and_5_count, between_4_and_5_amount] =
    ticketByTypes.count_between_4_and_5.split("|").map(Number);

  let [between_6_and_60_count, between_6_and_60_amount] =
    ticketByTypes.count_between_6_and_60.split("|").map(Number);

  let [between_61_and_120_count, between_61_and_120_amount] =
    ticketByTypes.count_between_61_and_120.split("|").map(Number);

  let [between_121_and_180_count, between_121_and_180_amount] =
    ticketByTypes.count_between_121_and_180.split("|").map(Number);

  let [more_than_180_count, more_than_180_amount] = ticketByTypes.more_than_180
    .split("|")
    .map(Number);

  let total_count =
    free_tickets_count +
    between_2_and_3_count +
    between_4_and_5_count +
    between_6_and_60_count +
    between_61_and_120_count +
    between_121_and_180_count +
    more_than_180_count +
    parseInt(currentDayReport?.count || 0);
  let total_amount =
    free_tickets_amount +
    between_2_and_3_amount +
    between_4_and_5_amount +
    between_6_and_60_amount +
    between_61_and_120_amount +
    between_121_and_180_amount +
    more_than_180_amount +
    parseInt(currentDayReport?.pt_amount || 0);
  doc.text(
    `Statistiques Du Parking: ${currentParking.title.trim()}`,
    pageWidth / 2,
    20,
    {
      align: "center",
    }
  );

  const base64Image = CGP_IMAGE;
  doc.addImage(base64Image, "PNG", pageWidth - 35, 12, 30, 13);
  // Subtitle
  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);
  doc.text(
    "Ce rapport présente les différents types de tickets et les montants collectés dans le parking",
    pageWidth / 2,
    40,
    {
      align: "center",
    }
  );
  //   doc.text(
  //     "sorties, les taux d'occupation, et d'autres indicateurs clés de performance pour le parking",
  //     40,
  //     44
  //   );
  doc.text(
    `${currentParking.title.trim()} entre ${startDate.format(
      "YYYY-MM-D"
    )} et ${startDate.format("YYYY-MM-D")}`,
    pageWidth / 2 - 15,
    46
  );

  // doc.(16);
  doc.setFont("helvetica", "normal");
  //   doc.text("Parking Bab Jdid P1-P2", 10, 84);

  // Adding a table
  const columns = [
    "Type de tickets",
    "Nombre de tickets",
    "Montant total (DH)",
  ];
  const rows = [
    ["Ticket gratuit", free_tickets_count, `${free_tickets_amount} DH`],
    ["Ticket perdus", currentDayReport.pt_count, currentDayReport.pt_amount],
    ["Ticket 1 heure", between_2_and_3_count, `${between_2_and_3_amount} DH`],
    ["Ticket 2 heures", between_4_and_5_count, `${between_4_and_5_amount} DH`],
    [
      "Ticket <= 60 DH & durée > 2 heures",
      between_6_and_60_count,
      `${between_6_and_60_amount} DH`,
    ],
    [
      "60 DH < Ticket <= 120 DH",
      between_61_and_120_count,
      `${between_61_and_120_amount} DH`,
    ],
    [
      "120 DH < Ticket <= 180 DH",
      between_121_and_180_count,
      `${between_121_and_180_amount} DH`,
    ],
    ["Ticket > 180 DH", more_than_180_count, `${more_than_180_amount} DH`],
    ["Total", total_count, `${total_amount} DH`],
    [
      "Ratio (DH/Ticket",
      { content: (total_amount / (total_count || 1)).toFixed(2), colSpan: 2 },
    ],
    // [
    //   { content: "Nomber Total", colSpan: 3 },
    //   { content: 601, colSpan: 2 },
    //   { content: 414, colSpan: 2 },
    //   "-",
    //   "4 Days",
    // ],
    // [
    //   { content: "Total", colSpan: 3 },
    //   { content: "-", colSpan: 2 },
    //   { content: "4525DH", colSpan: 2 },
    //   "40DH",
    //   "160DH",
    // ],
    // [
    //   { content: "Chiffre d'affaires global", colSpan: 5 },
    //   { content: "4521DH", colSpan: 5, fillColor: [0, 102, 204] },
    // ],
    // [{ content: "Nombre Total", colSpan: 2}, {content: 601, colSpan: 2}, 302, 112, 42, 3]
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
  ];
  console.log("====================================");
  //   console.log(data.row);
  console.log("====================================");
  // Use autoTable to generate the table
  doc.autoTable({
    startY: 60, // Y position where the table starts
    head: [columns],
    body: rows,
    theme: "grid",
    headStyles: {
      fillColor: [0, 102, 204], // Blue header background
      textColor: [255, 255, 255], // White header text
      fontSize: 10, // Font size of the header
    }, // Optional: 'striped', 'plain', or 'grid'
    styles: { halign: "center" }, // Optional: customize colors
  });

  // Save the PDF
  doc.setFont("helvetica");
  doc.setFontSize(12);
  doc.text("Compagnie General Des Parking", pageWidth / 2, pageHeight - 10, {
    align: "center",
  });
  doc.save("Rapport_Tickets_par_Types.pdf");
};

export const DailyReportPdf = (
  currentParking,
  restDataOfTheTable,
  currentDayReport,
  selectedDate
) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  // Title

  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.text(
    `Statistiques d'Abonnements Bab ${currentParking.title.trim()}`,
    pageWidth / 2,
    20,
    {
      align: "center",
    }
  );

  const base64Image = CGP_IMAGE;
  doc.addImage(base64Image, "PNG", pageWidth - 35, 12, 30, 13);
  // Subtitle
  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);
  doc.text(
    "Ce rapport présente le chiffres d'affaire des visiteurs ainsi que",
    pageWidth / 2 - 30,
    40
  );
  doc.text(
    "le détail des entrées sorties dans un parking pendant une période donnée.",
    pageWidth / 2 - 30,
    44
  );
  const bgColor = { r: 151, g: 200, b: 237 };
  doc.setFillColor(bgColor.r, bgColor.g, bgColor.b);
  doc.rect(0, 50, pageWidth, 10, "F");
  // Adding a line
  // doc.setLineWidth(0.5);
  // doc.line(10, 40, 200, 40);

  // Add some body text
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.text(`CA visiteurs du parkings ${currentParking.title.trim()}`, 10, 57);

  // doc.(16);
  doc.setFont("helvetica", "normal");
  doc.text(`Chiffre d'affaires du : ${selectedDate}`, 10, 70);
  doc.text(`Parking Bab ${currentParking.title.trim()}`, 10, 78);

  // Adding a table
  const columns = [
    "Parking",
    "",
    "",
    "N.E Jour",
    "N.E Nuit",
    "N.S Jour",
    "N.S Nuit",
    "N.T Illisible",
    "Tk.Perdus",
  ];
  const rows = [
    [
      { content: `${currentParking.title.trim()}`, colSpan: 3 },
      restDataOfTheTable.morning_entry_count,
      currentDayReport?.in_count || 0 - restDataOfTheTable.morning_entry_count,
      restDataOfTheTable.morning_exit_count,
      parseInt(currentDayReport?.out_count || 0) +
        parseInt(currentDayReport?.mt_count || 0) +
        parseInt(currentDayReport?.pt_count || 0) -
        restDataOfTheTable.morning_exit_count,
      currentDayReport?.mt_count || 0,
      currentDayReport?.pt_count || 0,
    ],
    [
      { content: "Nomber Total", colSpan: 3 },
      { content: currentDayReport?.in_count || 0, colSpan: 2 },
      {
        content:
          parseInt(currentDayReport?.out_count || 0) +
          parseInt(currentDayReport?.mt_count || 0) +
          parseInt(currentDayReport?.pt_count || 0),
        colSpan: 2,
      },
      "-",
      `${(currentDayReport?.pt_amount || 0) / 40} Days`,
    ],
    [
      { content: "Total", colSpan: 3 },
      { content: "-", colSpan: 2 },
      {
        content: `${parseInt(currentDayReport?.out_amount || 0)}HD`,
        colSpan: 2,
      },
      `${currentDayReport?.mt_amount || 0}DH`,
      `${currentDayReport?.pt_amount || 0}DH`,
    ],
    [
      { content: "Chiffre d'affaires global", colSpan: 5 },
      {
        content: `${
          parseInt(currentDayReport?.out_amount || 0) +
          parseInt(currentDayReport?.mt_amount || 0) +
          parseInt(currentDayReport?.pt_amount || 0)
        }HD`,
        colSpan: 5,
        fillColor: [0, 102, 204],
      },
    ],
    // [{ content: "Nombre Total", colSpan: 2}, {content: 601, colSpan: 2}, 302, 112, 42, 3]
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
  ];
  console.log("====================================");
  //   console.log(data.row);
  console.log("====================================");
  // Use autoTable to generate the table
  doc.autoTable({
    startY: 85, // Y position where the table starts
    head: [columns],
    body: rows,
    theme: "grid",
    headStyles: {
      fillColor: [0, 102, 204], // Blue header background
      textColor: [255, 255, 255], // White header text
      fontSize: 10, // Font size of the header
    }, // Optional: 'striped', 'plain', or 'grid'
    styles: { halign: "center" }, // Optional: customize colors
  });

  // Save the PDF
  doc.setFont("helvetica");
  doc.setFontSize(12);
  doc.text("Compagnie General Des Parking", pageWidth / 2, pageHeight - 10, {
    align: "center",
  });
  doc.save("CA_Visiteurs.pdf");
};
export const DailyReport_PeriodicPdf = (
  currentParking,
  restDataOfTheTable,
  currentDayReport,
  startDate,
  endDate
) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  // Title

  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.text(
    `Statistiques d'Abonnements Bab ${currentParking.title.trim()}`,
    pageWidth / 2,
    20,
    {
      align: "center",
    }
  );

  const base64Image = CGP_IMAGE;
  doc.addImage(base64Image, "PNG", pageWidth - 35, 12, 30, 13);
  // Subtitle
  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);
  doc.text(
    "Ce rapport présente le chiffres d'affaire des visiteurs ainsi que",
    pageWidth / 2 - 30,
    40
  );
  doc.text(
    "le détail des entrées sorties dans un parking pendant une période donnée.",
    pageWidth / 2 - 30,
    44
  );
  const bgColor = { r: 151, g: 200, b: 237 };
  doc.setFillColor(bgColor.r, bgColor.g, bgColor.b);
  doc.rect(0, 50, pageWidth, 10, "F");
  // Adding a line
  // doc.setLineWidth(0.5);
  // doc.line(10, 40, 200, 40);

  // Add some body text
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.text(`CA visiteurs du parkings ${currentParking.title.trim()}`, 10, 57);

  // doc.(16);
  doc.setFont("helvetica", "normal");
  doc.text(
    `Chiffre d'affaires enter le ${startDate.format(
      "YYYY-MM-DD"
    )} et ${endDate.format("YYYY-MM-DD")}`,
    10,
    70
  );
  doc.text(`Parking Bab ${currentParking.title.trim()}`, 10, 78);

  // Adding a table
  const columns = [
    "Parking",
    "",
    "",
    "N.E Jour",
    "N.E Nuit",
    "N.S Jour",
    "N.S Nuit",
    "N.T Illisible",
    "Tk.Perdus",
  ];
  const rows = [
    [
      { content: `${currentParking.title.trim()}`, colSpan: 3 },
      restDataOfTheTable.morning_entry_count,
      currentDayReport?.in_count || 0 - restDataOfTheTable.morning_entry_count,
      restDataOfTheTable.morning_exit_count,
      parseInt(currentDayReport?.out_count || 0) +
        parseInt(currentDayReport?.mt_count || 0) +
        parseInt(currentDayReport?.pt_count || 0) -
        restDataOfTheTable.morning_exit_count,
      currentDayReport?.mt_count || 0,
      currentDayReport?.pt_count || 0,
    ],
    [
      { content: "Nomber Total", colSpan: 3 },
      { content: currentDayReport?.in_count || 0, colSpan: 2 },
      {
        content:
          parseInt(currentDayReport?.out_count || 0) +
          parseInt(currentDayReport?.mt_count || 0) +
          parseInt(currentDayReport?.pt_count || 0),
        colSpan: 2,
      },
      "-",
      `${(parseInt(currentDayReport?.pt_amount) || 0) / 40} Days`,
    ],
    [
      { content: "Total", colSpan: 3 },
      { content: "-", colSpan: 2 },
      {
        content: `${parseInt(currentDayReport?.out_amount || 0)}HD`,
        colSpan: 2,
      },
      currentDayReport?.mt_amount || 0,
      currentDayReport?.pt_amount || 0,
    ],
    [
      { content: "Chiffre d'affaires global", colSpan: 5 },
      {
        content: `${
          parseInt(currentDayReport?.out_amount || 0) +
          parseInt(currentDayReport?.mt_amount || 0) +
          parseInt(currentDayReport?.pt_amount || 0)
        }HD`,
        colSpan: 5,
        fillColor: [0, 102, 204],
      },
    ],
    // [{ content: "Nombre Total", colSpan: 2}, {content: 601, colSpan: 2}, 302, 112, 42, 3]
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
    // [1, "John Doe", "2024-09-07", "$200", "754"],
  ];
  console.log("====================================");
  //   console.log(data.row);
  console.log("====================================");
  // Use autoTable to generate the table
  doc.autoTable({
    startY: 85, // Y position where the table starts
    head: [columns],
    body: rows,
    theme: "grid",
    headStyles: {
      fillColor: [0, 102, 204], // Blue header background
      textColor: [255, 255, 255], // White header text
      fontSize: 10, // Font size of the header
    }, // Optional: 'striped', 'plain', or 'grid'
    styles: { halign: "center" }, // Optional: customize colors
  });

  // Save the PDF
  doc.setFont("helvetica");
  doc.setFontSize(12);
  doc.text("Compagnie General Des Parking", pageWidth / 2, pageHeight - 10, {
    align: "center",
  });
  doc.save("CA_Visiteurs.pdf");
};

export const BillaZ = (data, parking_name) => {
  data = data.row;
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: [100, 350], // Custom width and height
  });
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  // Title

  doc.setFont("helvetica", "bold");
  doc.setFontSize(18);
  doc.text(`BillanZ`, pageWidth / 2, 15, {
    align: "center",
  });

  doc.setFont("helvetica", "normal");
  doc.setFontSize(12);
  doc.text(`Parking ${parking_name}`, pageWidth / 2, 22, {
    align: "center",
  });

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text("Date", 10, 35);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text(dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"), 55, 35);

  doc.setDrawColor(190, 190, 190);
  doc.line(10, 45, 90, 45);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text("Cassier", 10, 55);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text(`${data.username}`, 55, 55);

  doc.line(10, 65, 90, 65);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text("Debut Session", 10, 75);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text(`${dayjs(data.start_time).format("YYYY-MM-DD HH:mm:ss")}`, 55, 75);

  doc.line(10, 85, 90, 85);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text("Fin Session", 10, 95);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text(`${dayjs(data.end_time).format("YYYY-MM-DD HH:mm:ss")}`, 55, 95);

  doc.line(10, 105, 90, 105);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text("Nomber D'entre", 10, 115);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text(`${data.in_count}`, 55, 115);

  doc.line(10, 125, 90, 125);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.text("Sortie", 10, 135);

  doc.line(10, 145, 90, 145);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text("A:Transaction No", 10, 155);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text(`${data.out_count}`, 55, 155);

  doc.line(10, 165, 90, 165);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text("- Montant T.No", 15, 175);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text(`${data.normal_amout}Dh`, 55, 175);

  doc.line(10, 185, 90, 185);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text("B: Ticket Perdu", 10, 195);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text(`${data.penalty_count}`, 55, 195);

  doc.line(10, 205, 90, 205);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text("- Nomber De Jour", 15, 215);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text(`${parseInt(data.penalty_amount) / 40 || 0}`, 55, 215);

  doc.line(10, 225, 90, 225);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text("- Montant T.P", 15, 235);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text(`${data.penalty_amount}Dh`, 55, 235);

  doc.line(10, 245, 90, 245);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text("C: Nomber Enc.M", 10, 255);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text(`${data.manuel_count}`, 55, 255);

  doc.line(10, 265, 90, 265);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text(" - Montant Enc.M", 15, 275);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text(`${data.manuel_amount}Dh`, 55, 275);

  doc.line(10, 285, 90, 285);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text("Nbr.Trans Global", 10, 295);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text(
    `${
      parseInt(data.out_count) +
      parseInt(data.manuel_count) +
      parseInt(data.penalty_count)
    }`,
    55,
    295
  );

  doc.line(10, 305, 90, 305);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text("Chiffre D'affaire", 10, 315);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text(
    `${
      parseInt(data.normal_amout) +
      parseInt(data.penalty_amount) +
      parseInt(data.manuel_amount)
    }Dh`,
    55,
    315
  );
  // Save the PDF
  doc.setFont("helvetica");
  doc.setFontSize(12);
  doc.text("Compagnie General Des Parking", pageWidth / 2, pageHeight - 10, {
    align: "center",
  });
  doc.save("BillanZ.pdf");
};
